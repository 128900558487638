import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraTable } from '../../components/AtiraTable';
import { Flex } from '../../components/Flex';
import { Text } from '../../components/Text';
import { WarningModal } from '../../components/WarningModal';
import i18n, { AppLangs } from '../../i18n';
import { AtiraURL } from '../../model/url/AtiraURL';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { urlSliceSelectors } from '../../redux/url/url.selector';
import { shortenUrlActions } from '../../redux/url/url.slice';
import { userSliceSelectors } from '../../redux/user/user.selector';
import { Rounded } from '../../theme/Rounded';
import { AtiraToast } from '../../utils/AtiraToast';
import { createUserURLsTableColumns } from './createUserURLsTableColumns';

const tableColumnsKeys: (keyof AtiraURL | 'actions')[] = [
  'actions',
  'createdAt',
  'originalURL',
  'shortURL',
  'visits',
  'enabled',
];

const TableWrapper = styled.div`
  .ant-table-wrapper .ant-table-thead > tr > th {
    border-bottom: 0.1rem solid black;
  }

  .ant-table-wrapper .ant-table-tbody > tr > td {
    border-right: 0.01rem solid lightgrey;
  }

  .ant-table-tbody > tr > td {
    padding: 0 8px !important;
    height: 2.5rem;
  }

  border-radius: ${Rounded.md};
  overflow: hidden;
  .ant-table-cell-fix-left,
  .ant-table-cell-fix-right {
    background-color: ${({ theme }) => theme.light};
  }

  .ant-table-thead .ant-table-cell {
    background-color: ${({ theme }) => theme.light} !important;
    color: ${({ theme }) => theme.textColor} !important;
  }

  .ant-table-tbody {
    background-color: ${({ theme }) => theme.light} !important;
  }

  .ant-table-column-sorter svg {
    fill: ${({ theme }) => theme.textColor} !important ;
  }
`;

export const UserURLs: React.FC = () => {
  const [deleteURLLoading, setDeleteURLLoading] = useState(false);
  const [deleteURLModalVisibile, setDeleteURLModalVisibile] = useState(false);
  const [currentURLId, setCurrentURLId] = useState('');

  const { t } = useTranslation();

  const loggedInUserId = useAppSelector(
    userSliceSelectors.selectLoggedInUserId,
  )!;
  const userShortURLs = useAppSelector((s) =>
    urlSliceSelectors.selectShortURLs(s, loggedInUserId),
  );

  const dispatch = useAppDispatch();

  const showDeleteWarning = (urlId: string) => {
    setCurrentURLId(urlId);
    setDeleteURLModalVisibile(true);
  };

  const deleteURL = async () => {
    try {
      setDeleteURLLoading(true);

      await dispatch(
        shortenUrlActions.deleteURL({
          urlId: currentURLId,
          userId: loggedInUserId,
        }),
      ).unwrap();

      await dispatch(
        shortenUrlActions.getUserShortURLs({ userId: loggedInUserId }),
      ).unwrap();

      AtiraToast.success(t('shorten_url.delete.success'));
    } catch (e: any) {
      AtiraToast.apiError(e);
    } finally {
      setDeleteURLLoading(false);
      setDeleteURLModalVisibile(false);
      setCurrentURLId('');
    }
  };

  const enableURL = async (urlId: string) => {
    try {
      await dispatch(
        shortenUrlActions.enableUserURL({
          urlId,
          userId: loggedInUserId,
        }),
      ).unwrap();

      await dispatch(
        shortenUrlActions.getUserShortURLs({ userId: loggedInUserId }),
      ).unwrap();

      AtiraToast.success(t('url.enable.success'));
    } catch (e: any) {
      console.log(e);
      AtiraToast.apiError(e);
    }
  };

  const disableURL = async (urlId: string) => {
    try {
      await dispatch(
        shortenUrlActions.disableUserURL({
          urlId,
          userId: loggedInUserId,
        }),
      ).unwrap();
      AtiraToast.success(t('url.disable.success'));

      await dispatch(
        shortenUrlActions.getUserShortURLs({ userId: loggedInUserId }),
      ).unwrap();
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
    }
  };

  const tableColumns = createUserURLsTableColumns(tableColumnsKeys, {
    t,
    disable: disableURL,
    enable: enableURL,
    delete: showDeleteWarning,
  });

  return (
    <Flex width={'100%'} height={'100%'} flexDirection="column">
      <Flex flexDirection="column" marginBottom="m">
        <Flex
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          padding="s"
        >
          <Text>{t('shorten_url.your_short_urls')}</Text>

          <Text>
            {t('common.total')} {userShortURLs.length}
          </Text>
        </Flex>

        <TableWrapper>
          <AtiraTable
            key={JSON.stringify(tableColumns)}
            columns={tableColumns}
            data={userShortURLs}
            direction={i18n.language === AppLangs.AR ? 'rtl' : 'ltr'}
            scroll={{ x: 'max-content' }}
            rowHoverable={false}
            pagination={false}
            size="small"
            rowSelection={undefined}
            locale={{ emptyText: t('shorten_url.no_urls') }}
          />
        </TableWrapper>
      </Flex>

      <WarningModal
        onClose={() => setDeleteURLModalVisibile(false)}
        open={deleteURLModalVisibile}
        title={t('shorten_url.delete_modal.title')}
        description={t('shorten_url.delete_modal.description')}
        onConfirm={deleteURL}
        loading={deleteURLLoading}
      />

      <Text align="center" color="darkerSub">
        {t('shorten_url.my_urls.footer_paragraph')}
      </Text>
    </Flex>
  );
};
