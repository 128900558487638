import { createSlice } from '@reduxjs/toolkit';

import { AdminAxios } from '../../axios/admin/admin.axios';
import { AdminMessage } from '../../model/admin/AdminMessage';
import { AdminStats } from '../../model/admin/AdminStats';
import { AdminGetUserMessagesDto } from '../../model/admin/dto/AdminGetUserMessagesDto';
import { AdminGetUserMessagesResponseDto } from '../../model/admin/dto/AdminGetUserMessagesResponseDto';
import { AdminMessageUserDto } from '../../model/admin/dto/AdminMessageUserDto';
import { GetAdminStatsDto } from '../../model/admin/dto/GetAdminStatsDto';
import { GetReportsResponseDto } from '../../model/admin/dto/GetReportResponseDto';
import { GetReportsDto } from '../../model/admin/dto/GetReportsDto';
import { SearchUsersDto } from '../../model/admin/dto/SearchUsersDto';
import { SearchUsersResponseDto } from '../../model/admin/dto/SearchUsersResponseDto';
import { ToggleUserStatusDto } from '../../model/admin/dto/ToggleUserStatusDto';
import { PageDto } from '../../model/meta/PageDto';
import { AtiraThunk } from '../AtiraThunk';
import { userActions } from '../user/user.slice';

interface AdminReducer {
  stats: AdminStats;
  searchUsers: SearchUsersResponseDto['data'];
  searchUsersLoading: boolean;
  searchUsersMeta: PageDto;
  reports: GetReportsResponseDto['data'];
  reportsLoading: boolean;
  reportsTablePageSize: number;
  reportsTablePage: number;
  usersTablePageSize: number;
  usersTablePage: number;
  reportsMeta: GetReportsResponseDto['meta'];
  //
  adminMessages: AdminMessage[];
  adminMessagesLoading: boolean;
  adminMessagesPage: number;
  adminMessagesPageSize: number;
}

const initialState = Object.freeze<AdminReducer>({
  stats: {
    users: { lastMonth: 0, lastWeek: 0, total: 0 },
    atiraLinks: { lastMonth: 0, lastWeek: 0, total: 0 },
    QRs: { lastMonth: 0, lastWeek: 0, total: 0 },
    shortURLs: { lastMonth: 0, lastWeek: 0, total: 0 },
  },
  searchUsers: [],
  searchUsersLoading: false,
  searchUsersMeta: { count: 10, page: 0 },
  reportsMeta: { count: 10, page: 0 },
  reports: [],
  reportsLoading: false,
  reportsTablePage: 1,
  reportsTablePageSize: 0,
  usersTablePage: 1,
  usersTablePageSize: 0,
  adminMessages: [],
  adminMessagesLoading: false,
  adminMessagesPage: 1,
  adminMessagesPageSize: 20,
});

const getAdminStats = AtiraThunk<AdminStats, GetAdminStatsDto>(
  '/admin/stats',
  (dto) => AdminAxios.getAdminStats(dto),
);

const searchUsers = AtiraThunk<SearchUsersResponseDto, SearchUsersDto>(
  '/admin/user/search',
  (dto) => AdminAxios.searchUsers(dto),
);

const getReports = AtiraThunk<GetReportsResponseDto, GetReportsDto>(
  '/report',
  (dto) => AdminAxios.getReports(dto),
);

const toggleUserStatus = AtiraThunk<void, ToggleUserStatusDto>(
  '/enable/:id/user',
  (dto) => AdminAxios.toggleUserStatus(dto),
);

const sendMessageToUser = AtiraThunk<void, AdminMessageUserDto>(
  '/admin/user/:id/message',
  (dto) => AdminAxios.sendMessageToUser(dto),
);

const getAdminMessages = AtiraThunk<
  AdminGetUserMessagesResponseDto,
  AdminGetUserMessagesDto
>('/admin/messages', (dto) => AdminAxios.getAdminMessages(dto));

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setReportsTablePageSize: (state, action) => {
      state.reportsTablePageSize = action.payload;
    },

    setReportsTablePage: (state, action) => {
      state.reportsTablePage = action.payload;
    },

    setUsersTablePageSize: (state, action) => {
      state.usersTablePageSize = action.payload;
    },

    setUsersTablePage: (state, action) => {
      state.usersTablePage = action.payload;
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(getAdminStats.fulfilled, (state, action) => {
      state.stats = action.payload;
    });

    addCase(searchUsers.pending, (state) => {
      state.searchUsersLoading = true;
    });

    addCase(searchUsers.fulfilled, (state, action) => {
      state.searchUsers = action.payload.data || [];
      state.searchUsersMeta = action.payload.meta;
      state.searchUsersLoading = false;
    });

    addCase(searchUsers.rejected, (state) => {
      state.searchUsersLoading = false;
    });

    addCase(getReports.pending, (state) => {
      state.reportsLoading = true;
    });

    addCase(getReports.rejected, (state) => {
      state.reportsLoading = false;
    });

    addCase(getReports.fulfilled, (state, action) => {
      state.reportsLoading = false;
      state.reportsMeta = action.payload.meta;
      state.reports = action.payload.data || [];
    });

    addCase(getAdminMessages.pending, (state) => {
      state.adminMessagesLoading = true;
    });
    addCase(getAdminMessages.fulfilled, (state, action) => {
      state.adminMessages = action.payload.data;
      state.adminMessagesLoading = false;
      state.adminMessagesPageSize = action.payload.meta.count;
      state.adminMessagesPage = action.payload.meta.page;
    });

    addCase(userActions.logout.fulfilled, (state) => {
      return (state = initialState);
    });
  },
});

export const adminActions = {
  ...adminSlice.actions,
  getAdminStats,
  searchUsers,
  getReports,
  getAdminMessages,
  toggleUserStatus,
  sendMessageToUser,
};

export default adminSlice.reducer;
