export enum AtiraLinkThemes {
  BASIC = 'BASIC',
  URANUS = 'URANUS',
  HOTH = 'HOTH',
  EGO = 'EGO',
  EARTH = 'EARTH',
  MERCURY = 'MERCURY',
  JUPITER = 'JUPITER',
  MARS = 'MARS',
  NEPTUNE = 'NEPTUNE',
  SATURN = 'SATURN',
  THERA = 'THERA',
  MOON = 'MOON',
  GLIESE = 'GLIESE',
  KEPLER = 'KEPLER',
  VIOLA = 'VIOLA',
  CERULEAN = 'CERULEAN',
  ENCELADUS = 'ENCELADUS',
  DIMIDUM = 'DIMIDUM',
  OSIRIS = 'OSIRIS',
  CANCRI = 'CANCRI',
  EUROPA = 'EUROPA',
  VULCANO = 'VULCANO',
  CALLISTO = 'CALLISTO',
  AZURE = 'AZURE',
  CERES = 'CERES',
  MIMAS = 'MIMAS',
  PROXIMA = 'PROXIMA',
  PLUTO = 'PLUTO',
  HAUMEA = 'HAUMEA',
  PHOBOS = 'PHOBOS',
  GENYMADE = 'GENYMADE',
  ERIS = 'ERIS',
}
