export interface ThemeType {
  /* For backgrounds */
  transparent: string;
  dark: string;
  light: string;
  danger: string;
  success: string;
  //
  textColor: string;
  subTextColor: string;
  lightTextColor: string;
  //
  main: string;
  sub: string;
  darkSub: string;
  darkerSub: string;
  darkMain: string;
  lightMain: string;
  lightSub: string;
  accent: string;
  lightAccent: string;
}
/* eslint-disable */
export const LightTheme: ThemeType = {
  transparent: 'transparent',
  dark: '#000',
  light: '#fff',
  danger: '#f00',
  success: '#0f0',
  //
  textColor: '#000',
  lightTextColor: '#fff',
  subTextColor: '#5c5b5b',
  //
  main: '#652d90',
  sub: '#ebebeb',
  darkSub: '#d6d6d6',
  darkerSub: '#5c5b5b',
  darkMain: ' #e8eaea',
  lightMain: ' #934599',
  lightSub: '#f8f9fa',
  accent: '#5369a9',
  lightAccent: '#697ebc',
};
/* eslint-disable */
export const DarkTheme: ThemeType = {
  transparent: 'transparent',
  dark: '#121212',
  light: '#383b38',
  danger: '#f44336',
  success: '#0f0',
  //
  textColor: '#e0e0e0',
  lightTextColor: '#fafafa',
  subTextColor: '#b0b0b0',
  //
  main: '#904ac7',
  sub: '#2c2c2c',
  darkSub: '#434343',
  darkerSub: '#222222',
  darkMain: '#343434',
  lightMain: '#934599',
  lightSub: '#3a3a3a',
  accent: '#5369a9',
  lightAccent: '#697ebc',
};
