import { Collapse, CollapseProps } from 'antd';
import React from 'react';
import styled from 'styled-components';

const StyledCollapse = styled(Collapse)`
  overflow: hidden;
  width: 100%;

  .ant-collapse-header {
    align-items: center !important;
  }

  .ant-collapse-expand-icon svg {
    fill: ${({ theme }) => theme.textColor} !important;
  }

  .ant-collapse-content {
    background-color: inherit;
  }
`;

enum CollapsibleType {
  header = 'header',
  disabled = 'disabled',
  icon = 'icon',
}

enum ExpandIconPosition {
  start = 'start',
  end = 'end',
  right = 'right',
  left = 'left',
}

interface AtiraCollapseProps {
  items: CollapseProps['items'];
  defaultActiveKey?: string[];
  bordered?: boolean;
  accordion?: boolean;
  expandIconPosition?: keyof typeof ExpandIconPosition;
  collapsible?: keyof typeof CollapsibleType;
}

export const AtiraCollapse: React.FC<AtiraCollapseProps> = ({
  items = [],
  defaultActiveKey = ['1'],
  bordered,
  accordion,
  expandIconPosition = ExpandIconPosition.start,
  collapsible = CollapsibleType.header,
}) => {
  return (
    <StyledCollapse
      items={items}
      defaultActiveKey={defaultActiveKey}
      bordered={bordered}
      accordion={accordion}
      expandIconPosition={ExpandIconPosition[expandIconPosition]}
      collapsible={CollapsibleType[collapsible]}
    />
  );
};
