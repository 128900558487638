import { Table, TableProps } from 'antd';
import { TableRowSelection } from 'antd/es/table/interface';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AppLangs } from '../i18n';
import { SearchUsersUserDto } from '../model/admin/dto/SearchUsersUserDto';
import { AtiraLink } from '../model/atira-link/AtiraLink';
import { AtiraURL } from '../model/url/AtiraURL';
import { Flex } from './Flex';

interface AtiraTableProps<T extends object>
  extends Omit<TableProps<T>, 'dataSource'> {
  data: T[];
  columns: TableProps<T>['columns'];
  loading?: boolean;
  selectedRowKeys?: React.Key[];
  onSelectedRowKeysChange?: ATVoidFunction<React.Key[]>;
  size?: 'small';
}

const StyledTableWrapper = styled.div<{ size?: 'small' }>`
  .ant-table-wrapper .ant-table-thead > tr > th {
    border-bottom: 0.1rem solid black;
  }

  .ant-table-wrapper .ant-table-tbody > tr > td {
    border-right: 0.01rem solid lightgrey;
  }

  ${({ size }) =>
    size === 'small'
      ? `
  .ant-table-tbody > tr > td {
    padding: 0 8px !important;
    height: 2.5rem;
  }
`
      : ''}
`;

export function AtiraTable<
  T extends AtiraURL | SearchUsersUserDto | AtiraLink,
>({
  data,
  columns,
  loading = false,
  selectedRowKeys,
  onSelectedRowKeysChange,
  ...props
}: AtiraTableProps<T>) {
  const { i18n } = useTranslation();

  const rowSelection: TableRowSelection<T> = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[], selectedRows: T[]) =>
      onSelectedRowKeysChange?.(newSelectedRowKeys),
  };

  return (
    <Flex flexDirection="column" width="100%">
      <StyledTableWrapper size={props.size}>
        <Table
          dataSource={data.map((x, i) => ({
            ...x,
            key: `table-row-${i}`,
          }))}
          rowSelection={rowSelection}
          columns={columns?.filter((col: any) => col?.data?.enabled !== false)}
          scroll={{ x: 'max-content' }}
          loading={loading}
          style={{ overflowY: 'hidden', height: '100%' }}
          rowHoverable
          direction={i18n.language === AppLangs.AR ? 'rtl' : 'ltr'}
          {...props}
        />
      </StyledTableWrapper>
    </Flex>
  );
}
