import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons/faEllipsis';
import { faLink } from '@fortawesome/free-solid-svg-icons/faLink';
import { faLinkSlash } from '@fortawesome/free-solid-svg-icons/faLinkSlash';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { Dropdown, MenuProps, TableProps, Tooltip } from 'antd';
import { TFunction } from 'i18next';
import styled from 'styled-components';

import { AtiraIcon } from '../../components/AtiraIcon';
import { Button } from '../../components/Button';
import { CopyButton } from '../../components/CopyButton';
import { Flex } from '../../components/Flex';
import { Text } from '../../components/Text';
import { AtiraURL } from '../../model/url/AtiraURL';
import { Spacing } from '../../theme/Spacing';
import { standardDate } from '../../utils/Date';
import { truncateString } from '../../utils/String';

const StyledActionButton = styled(Button)`
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: ${Spacing.s};
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
`;

type FieldFunctions = {
  disable: ATVoidFunction<string>;
  enable: ATVoidFunction<string>;
  delete: ATVoidFunction<string>;
  t: TFunction;
};

const getTruncatedString = (value: string, length: number) => {
  if (value?.length >= length) {
    return (
      <Tooltip title={value}>
        <Text>{truncateString(value, length)}</Text>
      </Tooltip>
    );
  }

  return <Text>{value}</Text>;
};

const getFieldWidth = (field: keyof AtiraURL | 'actions') => {
  switch (field) {
    case 'originalURL':
    case 'shortURL':
      return '7rem';
    case 'actions':
      return '3rem';
    case 'visits':
    case 'createdAt':
    default:
      return '5rem';
  }
};

const getFieldInfo = (
  column: keyof AtiraURL | 'actions',
  functions: FieldFunctions,
) => {
  const { t, enable, disable, delete: deleteURL } = functions;

  switch (column) {
    case 'actions':
      return {
        title: t('common.actions'),
        key: 'actions',
        render: (url: AtiraURL) => {
          const items: MenuProps['items'] = [
            {
              key: 'g1',
              type: 'group',
              children: [
                {
                  label: (
                    <StyledActionButton onClick={() => enable(url._id)}>
                      <Flex gap="s" alignItems="center">
                        <AtiraIcon icon={faLink} color="main" />
                        <Text color="main">{t('common.enable')}</Text>
                      </Flex>
                    </StyledActionButton>
                  ),
                  valid: url.enabled === false,
                },
                {
                  label: (
                    <StyledActionButton onClick={() => disable(url._id)}>
                      <Flex gap="s" alignItems="center">
                        <AtiraIcon icon={faLinkSlash} color="main" />
                        <Text color="main">{t('common.disable')}</Text>
                      </Flex>
                    </StyledActionButton>
                  ),
                  valid: url.enabled === true,
                },
                {
                  label: (
                    <StyledActionButton onClick={() => deleteURL(url._id)}>
                      <Flex gap="s" alignItems="center">
                        <AtiraIcon icon={faTrash} color="main" />
                        <Text color="main">{t('common.delete')}</Text>
                      </Flex>
                    </StyledActionButton>
                  ),
                },
              ]
                .filter((child) => child.valid !== false)
                .map((child, index) => ({
                  ...child,
                  key: index,
                  style: { padding: 4 },
                })),
            },
          ];

          return (
            <Flex justifyContent="center">
              <Dropdown
                menu={{ items }}
                placement="bottomLeft"
                arrow={{ pointAtCenter: true }}
                trigger={['click']}
              >
                <Tooltip title={t('common.menu')}>
                  <Button
                    icon={faEllipsis}
                    backgroundColor="transparent"
                    margin="0"
                    color="textColor"
                  />
                </Tooltip>
              </Dropdown>
            </Flex>
          );
        },
        reorder: true,
        center: true,
        width: getFieldWidth('actions'),
        data: { column, enabled: true },
        fixed: 'left',
      };

    case 'originalURL':
      return {
        title: t('shorten_url.original_url'),
        key: 'originalURL',
        render: ({ originalURL }: AtiraURL) => {
          return (
            <Flex justifyContent="space-between" alignItems="center">
              {getTruncatedString(originalURL, 30)}
              <CopyButton copy={originalURL} />
            </Flex>
          );
        },
        width: getFieldWidth('originalURL'),
      };

    case 'shortURL':
      return {
        title: t('shorten_url.short_url'),
        key: 'shortURL',
        render: ({ shortURL }: AtiraURL) => {
          return (
            <Flex justifyContent="space-between" alignItems="center">
              {getTruncatedString(shortURL!, 25)}
              <CopyButton copy={shortURL!} />
            </Flex>
          );
        },
        width: getFieldWidth('shortURL'),
      };

    case 'createdAt':
      return {
        title: t('shorten_url.create_date'),
        key: 'time',
        render: ({ createdAt }: AtiraURL) => {
          return <Text>{standardDate(new Date(createdAt))}</Text>;
        },
        sorter: (a: AtiraURL, b: AtiraURL) => {
          const dateA = new Date(a.createdAt).getTime();
          const dateB = new Date(b.createdAt).getTime();
          return dateA - dateB;
        },
        sortable: true,
        reorder: true,
        width: getFieldWidth('createdAt'),
        data: { column, enabled: true },
        showSorterTooltip: false,
      };

    case 'visits':
      return {
        title: t('common.visits'),
        key: 'visits',
        render: ({ visits }: AtiraURL) => {
          return <Text>{visits}</Text>;
        },
        width: getFieldWidth('visits'),
      };

    case 'enabled':
      return {
        title: t('common.enabled'),
        key: 'visits',
        render: ({ enabled }: AtiraURL) => {
          return (
            <AtiraIcon
              icon={enabled ? faCheck : faXmark}
              color={enabled ? 'success' : 'danger'}
            />
          );
        },
        width: getFieldWidth('visits'),
      };

    default:
      return {
        title: column,
        key: column,
        render: (task: AtiraURL) =>
          getTruncatedString(task[column].toString() || '', 30),
        sortable: true,
        reorder: true,
        data: { column, enabled: true },
        width: getFieldWidth(column),
      };
  }
};

export const createUserURLsTableColumns = (
  fields: (keyof AtiraURL | 'actions')[],
  functions: FieldFunctions,
) => {
  return fields.map((field) =>
    getFieldInfo(field, functions),
  ) as TableProps<AtiraURL>['columns'];
};
