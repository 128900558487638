import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCamera } from '@fortawesome/free-solid-svg-icons/faCamera';
import isString from 'lodash/isString';
import React from 'react';

import { ThemeType } from '../theme/Theme';
import { getMediaUrl } from '../utils/Media';
import { AtiraIcon } from './AtiraIcon';
import { AtiraImage, AtiraImageProps } from './Image';

interface ImageCreateEditProps extends Omit<AtiraImageProps, 'src'> {
  icon?: IconProp;
  iconColor?: keyof ThemeType;
  src?: string;
}

export const ImageCreateEdit: React.FC<ImageCreateEditProps> = ({
  src,
  icon,
  iconColor,
  ...props
}) => {
  return src ? (
    <AtiraImage
      src={isString(src) ? getMediaUrl(src!) : URL.createObjectURL(src!)}
      {...props}
    />
  ) : (
    <AtiraIcon icon={icon || faCamera} color={iconColor || 'subTextColor'} />
  );
};
