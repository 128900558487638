import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { Spacing } from '../../../theme/Spacing';
import { SelfHostingVerificationTokenModal } from '../components/SelfHostingVerificationTokenModal';

const StartButton = styled(Button)`
  font-size: 1.5rem;
  padding: ${Spacing.m} ${Spacing.xl};

  &:hover {
    background-color: ${({ theme }) => theme.lightMain};
  }
`;

const ContentWrapper = styled(Flex)`
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${Spacing.m};
  width: min(40rem, 100%);
  margin: auto;
`;

export const SettingsSelfHosting: React.FC = () => {
  const [verificationModalVisible, setVerificationModalVisible] =
    useState(false);

  const { t } = useTranslation();

  return (
    <Flex width={'100%'} height={'100%'}>
      <ContentWrapper>
        <Text align="center" fontSize="l">
          {t('settings.self_hosting.soon')}
        </Text>

        <StartButton onClick={() => setVerificationModalVisible(true)} disabled>
          {t('settings.self_hosting.start')}
        </StartButton>
      </ContentWrapper>

      <SelfHostingVerificationTokenModal
        open={verificationModalVisible}
        onClose={() => setVerificationModalVisible(false)}
      />
    </Flex>
  );
};
