import { TabsProps } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraTabs } from '../../components/AtiraTabs';
import { Flex } from '../../components/Flex';
import { UserHeader } from '../../components/UserHeader';
import { Breakpoints } from '../../theme/Breakpoints';
import { Spacing } from '../../theme/Spacing';
import { SettingsLanguage } from './tabs/SettingsLanguage';
import { SettingsOpenGraph } from './tabs/SettingsOpenGraph';
import { SettingsSelfHosting } from './tabs/SettingsSelfHosting';

const UserHeaderWrapper = styled(Flex)`
  display: none;
  padding-top: ${Spacing.s};

  @media (min-width: ${Breakpoints.DESKTOP}) {
    display: flex;
  }
`;

export const SettingsRoute: React.FC = () => {
  const { t } = useTranslation();

  const items: TabsProps['items'] = [
    {
      key: 'open-graph',
      label: t('settings.open_graph'),
      children: <SettingsOpenGraph />,
    },
    {
      key: 'self-hosting',
      label: t('settings.self_hosting'),
      children: <SettingsSelfHosting />,
    },
    {
      key: 'link-lang',
      label: t('common.language'),
      children: <SettingsLanguage />,
    },
  ];

  return (
    <AtiraTabs
      items={items}
      sideComponent={
        <UserHeaderWrapper>
          <UserHeader />
        </UserHeaderWrapper>
      }
    />
  );
};
