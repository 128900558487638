import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faPen } from '@fortawesome/free-solid-svg-icons/faPen';
import { diff } from 'deep-object-diff';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { AtiraIcon } from '../../../components/AtiraIcon';
import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import i18n, { AppLangs } from '../../../i18n';
import { CreateAtiraLinkDto } from '../../../model/atira-link/dto/CreateAtiraLinkDto';
import { EditAtiraLinkDto } from '../../../model/atira-link/dto/EditAtiraLinkDto';
import { atiraLinkSliceSelectors } from '../../../redux/nerve/atira-link.selector';
import { AtiraLinkActions } from '../../../redux/nerve/atira-link.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { userSliceSelectors } from '../../../redux/user/user.selector';
import { Breakpoints } from '../../../theme/Breakpoints';
import { Rounded } from '../../../theme/Rounded';
import { Spacing } from '../../../theme/Spacing';
import { AtiraToast } from '../../../utils/AtiraToast';
import { AtiraLinkPreviewPhoneEJS } from './AtiraLinkPreviewPhoneEJS';

const Container = styled(Flex)`
  flex-direction: column;
  align-items: center;
  gap: ${Spacing.l};
  position: sticky;
  height: fit-content;
  width: 0;
  top: ${Spacing.m};
  margin-inline: ${Spacing.s};

  @media (min-width: ${Breakpoints.DESKTOP}) {
    width: 20rem;
  }
`;

const PhoneWrapper = styled(Flex)`
  border: 6px solid ${({ theme }) => theme.dark};
  border-radius: 2.5rem;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  background-color: ${({ theme }) => theme.light};
  overflow: hidden;
  width: 90%;
  background-color: white;

  display: none;

  @media (min-width: ${Breakpoints.DESKTOP}) {
    display: flex;
  }
`;

const PhoneHeader = styled(Flex)`
  width: 20vh;
  height: 3.7vh;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.dark};
  border-bottom-left-radius: 2.334vh;
  border-bottom-right-radius: 2.334vh;
`;

const ButtonsWrapper = styled(Flex)`
  position: fixed;
  bottom: 0;
  right: ${i18n.language === AppLangs.AR ? '50px' : '0'};
  left: ${i18n.language === AppLangs.AR ? '0' : '50px'};
  background-color: ${({ theme }) => theme.light};
  z-index: 100;
  justify-content: center;
  align-items: center;
  padding: ${Spacing.s};
  gap: ${Spacing.s};
  width: calc(100% - 50px);
  box-shadow: 0px 0px 7px rgba(50, 50, 50, 0.75);

  @media (min-width: ${Breakpoints.DESKTOP}) {
    position: static;
    background-color: ${({ theme }) => theme.transparent};
    box-shadow: none;
    width: 90%;
  }
`;

const SaveButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${Spacing.s};
  border-radius: ${Rounded.pill};
  text-decoration: none;
  background-color: ${({ theme }) => theme.main};
  color: ${({ theme }) => theme.lightTextColor};
  font-size: 1.1rem;
  padding: 0.6rem;
  height: 2.4rem;
  width: 10rem;

  &:hover {
    color: ${({ theme }) => theme.lightTextColor};
  }

  @media (min-width: ${Breakpoints.TABLET}) {
    font-size: 1.3rem;
  }
`;

const PreviewButton = styled(Button)`
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${Spacing.s};
  border-radius: ${Rounded.pill};
  font-size: 1.1rem;
  padding: 0.6rem;
  height: 2.4rem;
  width: 10rem;

  @media (min-width: ${Breakpoints.TABLET}) {
    font-size: 1.3rem;
  }
`;

interface AtiraLinkPreviewPhoneProps {
  loading: boolean;
  setLoading: ATVoidFunction<boolean>;
}

export const AtiraLinkPreviewPhone: React.FC<AtiraLinkPreviewPhoneProps> = ({
  loading,
  setLoading,
}) => {
  const { t } = useTranslation();
  const { getValues, watch, handleSubmit } = useFormContext<
    CreateAtiraLinkDto | EditAtiraLinkDto
  >();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;
  const atiraLink = useAppSelector(atiraLinkSliceSelectors.selectUserAtiraLink);
  const editMode = Boolean(atiraLink?._id);

  const [title, linkName] = watch(['title', 'linkName']);

  const onSave = async () => {
    try {
      setLoading(true);

      if (editMode) {
        await edit();
      } else {
        await create();
      }

      await dispatch(AtiraLinkActions.getUserAtiraLink({ userId })).unwrap();

      AtiraToast.success(
        t(editMode ? 'atira_link.edit.success' : 'atira_link.create.success'),
      );

      if (!editMode) {
        navigate('/dashboard');
      }
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const create = async () => {
    const dto = omit(getValues(), ['customLinks', 'socials']);

    const cleanedDto = omitBy(
      diff(omit(atiraLink, ['customLinks', 'socials'])!, dto),
      isNil,
    ) as CreateAtiraLinkDto;

    const customLinks = getValues('customLinks');
    const customLinksAreEmpty = !customLinks?.filter(
      ({ label, value }) => label.length && value.length,
    ).length;

    if (!customLinksAreEmpty) {
      cleanedDto.customLinks = customLinks?.filter(
        ({ label, value }) => label.length && value.length,
      );
    } else {
      delete cleanedDto.customLinks;
    }

    const socials = getValues('socials');

    if (socials?.length) {
      cleanedDto.socials = socials;
    } else {
      delete cleanedDto.socials;
    }

    cleanedDto.userId = userId;

    return dispatch(AtiraLinkActions.createAtiraLink(cleanedDto)).unwrap();
  };

  const edit = async () => {
    const dto = omit(getValues(), ['customLinks', 'socials']);

    const cleanedDto = omitBy(
      diff(omit(atiraLink, ['customLinks', 'socials'])!, dto),
      isNil,
    ) as CreateAtiraLinkDto;

    const customLinks = getValues('customLinks');
    const customLinksAreEmpty = !customLinks?.filter(
      ({ label, value }) => label.length && value.length,
    ).length;

    if (!customLinksAreEmpty) {
      cleanedDto.customLinks = customLinks?.filter(
        ({ label, value }) => label.length && value.length,
      );
    } else {
      cleanedDto.customLinks = [];
    }

    const socials = getValues('socials');

    cleanedDto.socials = socials;

    cleanedDto.userId = userId;

    return dispatch(
      AtiraLinkActions.editAtiraLink({
        atiraLinkId: atiraLink!._id,
        ...cleanedDto,
      } as EditAtiraLinkDto),
    ).unwrap();
  };

  const onPreview = () => {
    window.open(`https://atrl.ink/${atiraLink?.linkName}`, '_blank');
  };

  return (
    <Container>
      <PhoneWrapper>
        <PhoneHeader />

        <AtiraLinkPreviewPhoneEJS />
      </PhoneWrapper>

      <ButtonsWrapper>
        <SaveButton
          disabled={(title?.length || 0) < 3 || linkName?.length < 3}
          onClick={handleSubmit(onSave)}
          loading={loading}
        >
          <AtiraIcon icon={editMode ? faPen : faCheck} />

          {editMode ? t('common.update') : t('common.save')}
        </SaveButton>

        <PreviewButton onClick={onPreview} disabled={!linkName}>
          <AtiraIcon icon={faEye} />
          {t('common.preview')}
        </PreviewButton>
      </ButtonsWrapper>
    </Container>
  );
};
