import { faEllipsis } from '@fortawesome/free-solid-svg-icons/faEllipsis';
import { faMessage } from '@fortawesome/free-solid-svg-icons/faMessage';
import { faToggleOff } from '@fortawesome/free-solid-svg-icons/faToggleOff';
import { faToggleOn } from '@fortawesome/free-solid-svg-icons/faToggleOn';
import { Dropdown, MenuProps, TableProps } from 'antd';
import { TFunction } from 'i18next';
import styled from 'styled-components';

import { Button } from '../../../components/Button';
import { CopyButton } from '../../../components/CopyButton';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { TextWithTruncated } from '../../../components/TextWithTruncated';
import { SearchUsersUserDto } from '../../../model/admin/dto/SearchUsersUserDto';
import { Spacing } from '../../../theme/Spacing';

type UserFNType = ATVoidFunction<Partial<SearchUsersUserDto>>;

type FieldFunctions = {
  t: TFunction;
  toggleStatus: UserFNType;
  sendMessage: UserFNType;
};

const StyledCopyButton = styled(CopyButton)`
  padding: 0;
`;

const MenuButton = styled(Button)`
  margin: 0;
  background-color: ${({ theme }) => theme.transparent};
  color: ${({ theme }) => theme.main};
`;

const StyledActionButton = styled(Button)`
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: ${Spacing.s};
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.main};
  font-size: 1rem;
`;

const getFieldWidth = (
  field:
    | keyof SearchUsersUserDto
    | keyof SearchUsersUserDto['user']
    | 'actions',
) => {
  switch (field) {
    case 'actions':
      return '5%';
    case 'name':
      return 140;
    case 'email':
      return 175;
    case 'kind':
    case 'createdAt':
    case 'atiraLinkCount':
    case 'shortURLsCount':
    case 'qrsCount':
      return 100;

    default:
      return 100;
  }
};

const getFieldInfo = (
  column:
    | keyof SearchUsersUserDto
    | keyof SearchUsersUserDto['user']
    | 'actions',
  functions: FieldFunctions,
) => {
  const { t } = functions;

  switch (column) {
    case 'actions':
      return {
        title: t('common.actions'),
        key: 'table-actions',
        data: column,
        render: (user: SearchUsersUserDto) => {
          const isEnabled = user.user.enabled === true;
          const items: MenuProps['items'] = [
            {
              key: 'enable-disable',
              label: (
                <StyledActionButton
                  icon={isEnabled ? faToggleOff : faToggleOn}
                  onClick={() => functions.toggleStatus(user)}
                >
                  {t(isEnabled ? 'common.disable' : 'common.enable')}
                </StyledActionButton>
              ),
            },
            {
              key: 'send-message',
              label: (
                <StyledActionButton
                  icon={faMessage}
                  onClick={() => functions.sendMessage(user)}
                >
                  {t('common.message')}
                </StyledActionButton>
              ),
            },
          ];
          return (
            <Dropdown menu={{ items }} trigger={['click']}>
              <MenuButton icon={faEllipsis} />
            </Dropdown>
          );
        },

        fixed: 'left',
        width: getFieldWidth(column),
      };

    case 'name':
      return {
        title: t('common.name'),
        key: 'table-name',
        data: column,
        render: (text: string, record: SearchUsersUserDto) => {
          const name = record.user?.name;
          return name ? (
            <TextWithTruncated length={20}>{name}</TextWithTruncated>
          ) : (
            ''
          );
        },
        sorter: (a: SearchUsersUserDto, b: SearchUsersUserDto) =>
          a.user?.name?.localeCompare(b.user?.name),
        width: getFieldWidth(column),
      };

    case 'email':
      return {
        title: t('common.email'),
        key: 'table-email',
        data: column,
        render: (text: string, record: SearchUsersUserDto) => {
          const email = record.user?.email;
          return email ? (
            <Flex alignItems="center" justifyContent="space-between" gap="m">
              <TextWithTruncated length={25}>{email}</TextWithTruncated>
              <StyledCopyButton copy={email} />
            </Flex>
          ) : (
            ''
          );
        },
        width: getFieldWidth(column),
      };

    case 'kind':
      return {
        title: t('common.kind'),
        key: 'table-kind',
        data: column,
        render: (text: string, record: SearchUsersUserDto) => {
          const kind = record.user?.kind;
          return kind ? <Text>{t(`common.${kind.toLowerCase()}`)}</Text> : null;
        },
        width: getFieldWidth(column),
      };

    case 'atiraLinkCount':
      return {
        title: t('common.atira_links'),
        key: 'table-atira-link-count',
        render: (user: SearchUsersUserDto) => {
          return <Text>{user.atiraLinkCount}</Text>;
        },

        sortable: true,
        reorder: true,
        data: column,
        width: getFieldWidth(column),
      };

    case 'qrsCount':
      return {
        title: t('common.qrs'),
        key: 'table-qrs-count',
        render: (user: SearchUsersUserDto) => {
          return <Text>{user.shortURLsCount}</Text>;
        },

        sortable: true,
        reorder: true,
        data: column,
        width: getFieldWidth(column),
      };

    case 'shortURLsCount':
      return {
        title: t('common.short_urls'),
        key: 'table-short-urls-count',
        render: (user: SearchUsersUserDto) => {
          return <Text>{user.shortURLsCount}</Text>;
        },

        sortable: true,
        reorder: true,
        data: column,
        width: getFieldWidth(column),
      };

    case 'createdAt':
      return {
        title: t('common.created_at'),
        key: 'table-createdAt',
        data: column,
        render: (text: string, record: SearchUsersUserDto) => {
          const createdAt = record.user.createdAt;
          return createdAt ? new Date(createdAt).toLocaleDateString() : '';
        },
        sorter: (a: SearchUsersUserDto, b: SearchUsersUserDto) =>
          new Date(a.user.createdAt).getTime() -
          new Date(b.user.createdAt).getTime(),
        width: getFieldWidth('createdAt'),
      };
  }
};

export function createAdminSearchUsersTableColumns<T>(
  functions: FieldFunctions,
) {
  const tableColumnsKeys: (
    | keyof SearchUsersUserDto
    | keyof SearchUsersUserDto['user']
    | 'actions'
  )[] = [
    'actions',
    'name',
    'email',
    'kind',
    'createdAt',
    'atiraLinkCount',
    'shortURLsCount',
    'qrsCount',
  ];

  return tableColumnsKeys.map((c) =>
    getFieldInfo(c, functions),
  ) as TableProps<T>['columns'];
}
