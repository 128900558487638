import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook';
import { faGithub } from '@fortawesome/free-brands-svg-icons/faGithub';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin';
import { faPinterest } from '@fortawesome/free-brands-svg-icons/faPinterest';
import { faReddit } from '@fortawesome/free-brands-svg-icons/faReddit';
import { faSnapchat } from '@fortawesome/free-brands-svg-icons/faSnapchat';
import { faSpotify } from '@fortawesome/free-brands-svg-icons/faSpotify';
import { faTelegram } from '@fortawesome/free-brands-svg-icons/faTelegram';
import { faTiktok } from '@fortawesome/free-brands-svg-icons/faTiktok';
import { faTwitch } from '@fortawesome/free-brands-svg-icons/faTwitch';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons/faWhatsapp';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons/faXTwitter';
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { Row } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { AtiraColumn } from '../../components/AtiraColumn';
import { AtiraIcon } from '../../components/AtiraIcon';
import { CopyButton } from '../../components/CopyButton';
import { Flex } from '../../components/Flex';
import { PageHeader } from '../../components/PageHeader';
import { Spinner } from '../../components/Spinner';
import { Text } from '../../components/Text';
import { UserHeader } from '../../components/UserHeader';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { AtiraLinkSocialHandlers } from '../../model/atira-link/types/AtiraLinkSocialMediaHandlers.enum';
import { atiraLinkSliceSelectors } from '../../redux/nerve/atira-link.selector';
import { AtiraLinkActions } from '../../redux/nerve/atira-link.slice';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { userSliceSelectors } from '../../redux/user/user.selector';
import { Breakpoints } from '../../theme/Breakpoints';
import { Rounded } from '../../theme/Rounded';
import { Spacing } from '../../theme/Spacing';

const socialMediaIcons: Record<AtiraLinkSocialHandlers, IconDefinition> = {
  WHATSAPP: faWhatsapp,
  INSTAGRAM: faInstagram,
  TWITTER: faXTwitter,
  FACEBOOK: faFacebook,
  YOUTUBE: faYoutube,
  TELEGRAM: faTelegram,
  TIKTOK: faTiktok,
  SNAPCHAT: faSnapchat,
  REDDIT: faReddit,
  LINKEDIN: faLinkedin,
  PINTEREST: faPinterest,
  SPOTIFY: faSpotify,
  TWITCH: faTwitch,
  GITHUB: faGithub,
};

const SectionWrapper = styled(Flex)`
  flex-direction: column;
  gap: ${Spacing.s};
`;

const Section = styled(Flex)`
  background-color: ${({ theme }) => theme.light};
  border-radius: ${Rounded.lg};
  padding: ${Spacing.m};
  flex-direction: column;
  gap: ${Spacing.m};
`;

const InputSection = styled(Flex)`
  background-color: ${({ theme }) => theme.light};
  border-radius: ${Rounded.lg};
  padding: ${Spacing.m};
  align-items: center;
  width: auto;
  gap: 5px;

  @media (max-width: ${Breakpoints.TABLET}) {
    flex-direction: column;
    align-items: start;
  }
`;

const StyledButton = styled(CopyButton)`
  background-color: ${({ theme }) => theme.light};
  border-radius: ${Rounded.lg};
  padding: ${Spacing.m};
  margin: 0rem;
  @media (max-width: ${Breakpoints.TABLET}) {
    display: none;
  }
`;

const StyledResponsiveButton = styled(CopyButton)`
  font-size: 0.5rem;
  margin: 0rem;
  padding: ${Spacing.s};
  @media (min-width: ${Breakpoints.TABLET}) {
    display: none;
  }
`;

const SectionTitle = styled(Text)`
  color: ${({ theme }) => theme.textColor};
  font-weight: bold;
  font-size: 1.3rem;
`;

const SectionSecondTitle = styled(Text)`
  color: ${({ theme }) => theme.textColor};
  font-weight: bold;
  font-size: 1rem;
`;

const StyledNumber = styled(Text)`
  color: ${({ theme }) => theme.textColor};
`;

const Responsive = styled(Flex)`
  width: 100%;
  height: fit-content;

  @media (max-width: ${Breakpoints.TABLET}) {
    gap: ${Spacing.m};
    flex-direction: column;
  }
`;

const TextsWrapper = styled(Flex)`
  gap: ${Spacing.s};

  flex-wrap: wrap;
`;

const UserHeaderWrapper = styled(Flex)`
  display: none;

  @media (min-width: ${Breakpoints.DESKTOP}) {
    display: flex;
  }
`;

export const Dashboard: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  useDocumentTitle(
    `${t('app.name').toUpperCase()} - ${t('side_menu.dashboard')}`,
  );

  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;
  const userLink = useAppSelector(atiraLinkSliceSelectors.selectUserAtiraLink);
  const userLinkLoading = useAppSelector(
    atiraLinkSliceSelectors.selectUserAtiraLinkLoading,
  );
  const stats = useAppSelector(
    atiraLinkSliceSelectors.selectUserAtiraLinkStats,
  );
  const userLinkUrl = `https://atrl.ink/${userLink?.linkName}`;

  const activityVisits = (stats?.MOBILE.view || 0) + (stats?.DESKTOP.view || 0);
  const activityUniqueVisits =
    (stats?.MOBILE.uniqueView || 0) + (stats?.DESKTOP.uniqueView || 0);
  const activityClicks =
    (stats?.MOBILE.click || 0) + (stats?.DESKTOP.click || 0);
  const activityUniqueClicks =
    (stats?.MOBILE.uniqueClick || 0) + (stats?.DESKTOP.uniqueClick || 0);
  const activityCtr = ((activityClicks / activityVisits) * 100 || 0).toFixed(1);

  useEffect(() => {
    dispatch(AtiraLinkActions.getUserAtiraLink({ userId }));
  }, [dispatch, userId]);

  return (
    <Flex gap="m" margin="m" flexDirection="column" flex={1}>
      <PageHeader
        leftComponent={
          !isEmpty(userLink) ? (
            <Flex gap="s">
              <InputSection>
                <Text color="main" fontSize="l" fontWeight={'bolder'}>
                  {t('common.your_link')}:
                </Text>
                <Flex alignItems="center">
                  <Text fontSize="l" cutText>
                    {userLinkUrl}
                  </Text>
                  <StyledResponsiveButton copy={userLinkUrl} />
                </Flex>
              </InputSection>
              <StyledButton copy={userLinkUrl} />
            </Flex>
          ) : (
            <InputSection
              width={'17rem'}
              cursor="pointer"
              gap="l"
              onClick={() => navigate('/my-link')}
            >
              {userLinkLoading ? (
                <Spinner />
              ) : (
                <Text color="main" fontSize="l" fontWeight={'bolder'}>
                  {t('dashboard.create_link')}
                </Text>
              )}
              <AtiraIcon size="2x" icon={faArrowRight} color="textColor" />
            </InputSection>
          )
        }
        rightComponent={
          <UserHeaderWrapper>
            <UserHeader />
          </UserHeaderWrapper>
        }
      />

      <Flex gap="m" flex={1}>
        <SectionWrapper width={'100%'}>
          <Section>
            <SectionTitle>{t('common.activity')}</SectionTitle>

            <Row justify={'space-between'}>
              <Responsive>
                <AtiraColumn>
                  <TextsWrapper>
                    <Text fontWeight={'bolder'}>{activityVisits}</Text>
                    <StyledNumber>{t('common.views')}</StyledNumber>
                    <Text>/</Text>
                    <Text fontWeight={'bolder'}>{activityUniqueVisits}</Text>
                    <StyledNumber>{t('common.unique_views')}</StyledNumber>
                  </TextsWrapper>
                </AtiraColumn>

                <AtiraColumn>
                  <TextsWrapper>
                    <Text fontWeight={'bolder'}>{activityClicks}</Text>
                    <StyledNumber>{t('common.clicks')}</StyledNumber>
                    <Text>/</Text>
                    <Text fontWeight={'bolder'}>{activityUniqueClicks}</Text>
                    <StyledNumber>{t('common.unique_clicks')}</StyledNumber>
                  </TextsWrapper>
                </AtiraColumn>

                <AtiraColumn xl={4}>
                  <Flex gap="s">
                    <Text fontWeight={'bolder'}>{activityCtr}%</Text>
                    <StyledNumber fontWeight={'bolder'}>
                      {t('common.ctr')}
                    </StyledNumber>
                  </Flex>
                </AtiraColumn>
              </Responsive>
            </Row>
          </Section>

          <Section>
            <SectionTitle>{t('common.device')}</SectionTitle>
            <Flex flexDirection="column" gap="m">
              <SectionSecondTitle>{t('common.mobile')}</SectionSecondTitle>

              <Row justify={'space-between'}>
                <Responsive>
                  <AtiraColumn>
                    <TextsWrapper>
                      <Text fontWeight={'bolder'}>{stats?.MOBILE.view}</Text>
                      <StyledNumber>{t('common.views')}</StyledNumber>
                      <Text>/</Text>
                      <Text fontWeight={'bolder'}>
                        {stats?.MOBILE.uniqueView}
                      </Text>
                      <StyledNumber>{t('common.unique_views')}</StyledNumber>
                    </TextsWrapper>
                  </AtiraColumn>

                  <AtiraColumn>
                    <TextsWrapper>
                      <Text fontWeight={'bolder'}>{stats?.MOBILE.click}</Text>
                      <StyledNumber>{t('common.clicks')}</StyledNumber>
                      <Text>/</Text>
                      <Text fontWeight={'bolder'}>
                        {stats?.MOBILE.uniqueClick}
                      </Text>
                      <StyledNumber>{t('common.unique_clicks')}</StyledNumber>
                    </TextsWrapper>
                  </AtiraColumn>

                  <AtiraColumn xl={4}>
                    <TextsWrapper>
                      <Text fontWeight={'bolder'}>{stats?.MOBILE.ctr}</Text>
                      <StyledNumber fontWeight={'bolder'}>
                        {t('common.ctr')}
                      </StyledNumber>
                    </TextsWrapper>
                  </AtiraColumn>
                </Responsive>
              </Row>

              <SectionSecondTitle>{t('common.desktop')}</SectionSecondTitle>
              <Row justify={'space-between'}>
                <Responsive>
                  <AtiraColumn>
                    <TextsWrapper>
                      <Text fontWeight={'bolder'}>{stats?.MOBILE.view}</Text>
                      <StyledNumber>{t('common.views')}</StyledNumber>
                      <Text>/</Text>
                      <Text fontWeight={'bolder'}>
                        {stats?.MOBILE.uniqueView}
                      </Text>
                      <StyledNumber>{t('common.unique_views')}</StyledNumber>
                    </TextsWrapper>
                  </AtiraColumn>

                  <AtiraColumn>
                    <TextsWrapper>
                      <Text fontWeight={'bolder'}>{stats?.MOBILE.click}</Text>
                      <StyledNumber>{t('common.clicks')}</StyledNumber>
                      <Text>/</Text>
                      <Text fontWeight={'bolder'}>
                        {stats?.MOBILE.uniqueClick}
                      </Text>
                      <StyledNumber>{t('common.unique_clicks')}</StyledNumber>
                    </TextsWrapper>
                  </AtiraColumn>

                  <AtiraColumn xl={4}>
                    <TextsWrapper>
                      <Text fontWeight={'bolder'}>{stats?.MOBILE.ctr}</Text>
                      <StyledNumber fontWeight={'bolder'}>
                        {t('common.ctr')}
                      </StyledNumber>
                    </TextsWrapper>
                  </AtiraColumn>
                </Responsive>
              </Row>
            </Flex>
          </Section>

          <Section>
            <SectionTitle> {t('common.social_media')}</SectionTitle>
            <Flex flexDirection="column" gap="m">
              <Row justify={'space-between'}>
                <Responsive>
                  <AtiraColumn>
                    <Flex flexDirection="column" gap="m">
                      {stats?.SOCIAL?.map((clickItem, index) => (
                        <Flex
                          gap="s"
                          alignItems="center"
                          key={`${clickItem}-${index}`}
                        >
                          <AtiraIcon
                            icon={
                              socialMediaIcons[
                                clickItem.handler as AtiraLinkSocialHandlers
                              ]
                            }
                            size="2x"
                            color="textColor"
                          />
                          <Text
                            fontWeight={'bolder'}
                          >{`${clickItem.count} `}</Text>
                          <StyledNumber>
                            {t(`common.${clickItem.handler.toLowerCase()}`)}
                          </StyledNumber>
                        </Flex>
                      ))}
                    </Flex>
                  </AtiraColumn>
                </Responsive>
              </Row>
            </Flex>
          </Section>

          {stats?.COUNTRIES.length ? (
            <Section>
              <SectionTitle>{t('common.country_and_visits')}</SectionTitle>
              <Flex flexDirection="column" gap="m">
                <Row justify={'space-between'}>
                  <AtiraColumn>
                    <Flex gap="s">
                      <Text fontWeight={'bolder'}>
                        {stats?.COUNTRIES.map((country, index) => (
                          <Flex key={index}>
                            {country.country}: {country.count}
                          </Flex>
                        ))}
                      </Text>
                    </Flex>
                  </AtiraColumn>
                </Row>
              </Flex>
            </Section>
          ) : null}
        </SectionWrapper>
      </Flex>
    </Flex>
  );
};
