import { faCamera } from '@fortawesome/free-solid-svg-icons/faCamera';
import { Tooltip } from 'antd';
import isString from 'lodash/isString';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { ImageCreateEdit } from '../../../components/ImageCreateEdit';
import { ImageUpload } from '../../../components/ImageUpload';
import { Input } from '../../../components/Input';
import { Text } from '../../../components/Text';
import { Textarea } from '../../../components/Textarea';
import { EditAtiraLinkDto } from '../../../model/atira-link/dto/EditAtiraLinkDto';
import { atiraLinkSliceSelectors } from '../../../redux/nerve/atira-link.selector';
import { AtiraLinkActions } from '../../../redux/nerve/atira-link.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { userSliceSelectors } from '../../../redux/user/user.selector';
import { Breakpoints } from '../../../theme/Breakpoints';
import { Rounded } from '../../../theme/Rounded';
import { Spacing } from '../../../theme/Spacing';
import { AtiraToast } from '../../../utils/AtiraToast';
import { getMediaUrl } from '../../../utils/Media';

const Container = styled(Flex)`
  flex-direction: column;
  gap: ${Spacing.m};
  width: 100%;
  align-items: flex-end;

  @media (min-width: ${Breakpoints.DESKTOP}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const SectionTitle = styled(Text)`
  color: ${({ theme }) => theme.textColor};
  font-weight: bold;
  font-size: 1.3rem;
`;

const SectionWrapper = styled(Flex)`
  flex-direction: column;
  gap: ${Spacing.s};
  width: 100%;

  @media (min-width: ${Breakpoints.DESKTOP}) {
    min-width: 65%;
  }
`;

const Section = styled(Flex)`
  background-color: ${({ theme }) => theme.light};
  border-radius: ${Rounded.lg};
  padding: ${Spacing.m};
  flex-direction: column;
  gap: ${Spacing.s};

  @media (min-width: ${Breakpoints.DESKTOP}) {
    gap: ${Spacing.m};
    flex-direction: row;
    align-items: center;
    height: 11rem;
  }
`;

const InputWrapper = styled(Flex)`
  border-radius: ${Rounded.md};
  padding-block: 0.2rem;
  flex-direction: column;
  gap: ${Spacing.s};

  @media (min-width: ${Breakpoints.DESKTOP}) {
    flex-direction: row;
    align-items: center;
    gap: 0;
    background-color: ${({ theme }) => theme.darkSub};
    padding-inline-start: ${Spacing.s};
  }
`;

const StyledInput = styled(Input)`
  background-color: ${({ theme }) => theme.darkSub};
  font-size: 0.8rem;
  font-weight: 600;

  @media (min-width: ${Breakpoints.TABLET}) {
    font-size: 1rem;
  }

  @media (min-width: ${Breakpoints.DESKTOP}) {
    background-color: ${({ theme }) => theme.transparent};
  }
`;

const InputLabel = styled(Text)`
  color: ${({ theme }) => theme.main};
  font-size: 1rem;
  font-weight: 600;
  white-space: nowrap;

  @media (min-width: ${Breakpoints.TABLET}) {
    font-size: 1.2rem;
  }
`;

const StyledTextarea = styled(Textarea)`
  background-color: ${({ theme }) => theme.darkSub};
  border: 0;
  font-size: 0.8rem;
  font-weight: 600;
  resize: vertical;
  padding: 0.2rem ${Spacing.s} !important;

  @media (min-width: ${Breakpoints.TABLET}) {
    font-size: 1rem;
  }

  @media (min-width: ${Breakpoints.DESKTOP}) {
    background-color: ${({ theme }) => theme.transparent};
  }
`;

const ImageUploadWrapper = styled(Flex)`
  justify-content: center;

  .ant-upload-wrapper {
    border: ${({ theme }) => `1px solid ${theme.main}`} !important;
    border-radius: ${Rounded.circle} !important;
  }

  .ant-upload-select {
    border-radius: ${Rounded.circle} !important;
    border: 0 !important;
    overflow: hidden !important;
  }

  .ant-upload-list-picture-card {
    display: block !important;
    position: relative !important;
  }

  .ant-upload-list-item {
    border: 0 !important;
    padding: 0 !important;
    border-radius: ${Rounded.circle} !important;
    overflow: hidden !important;
  }

  .ant-upload-list-item-container {
    position: absolute !important;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }

  .ant-upload-list-item-actions {
    z-index: 5000 !important;
  }

  .ant-upload-list-item::before {
    width: 100% !important;
    height: 100% !important;
  }

  button {
    width: 100%;
    height: 100%;

    &:hover {
      background-color: ${({ theme }) => theme.lightMain} !important;
    }
  }

  @media (min-width: ${Breakpoints.DESKTOP}) {
    justify-content: flex-start;
  }
`;

const PreviewWrapper = styled(Flex)`
  flex-direction: column;
  gap: ${Spacing.m};
  border-radius: ${Rounded.md};
  padding: ${Spacing.m};
  background-color: ${({ theme }) => theme.light};
  width: 100%;

  @media (min-width: ${Breakpoints.DESKTOP}) {
    display: flex;
    min-width: 34%;
    height: 11rem;
  }
`;

const PreviewInnerWrapper = styled(Flex)`
  border: ${({ theme }) => `1px solid ${theme.textColor}`};
  border-radius: ${Rounded.lg};
  overflow: hidden;
  width: 100%;
`;

const PreviewImageWrapper = styled(Flex)`
  width: 5rem;
  flex-shrink: 0;
  aspect-ratio: 1 / 1;

  svg {
    width: 70%;
    height: 70%;
    display: block;
    margin: auto;
    padding: ${Spacing.s};
  }

  img {
    height: 100% !important;
    object-fit: cover;
  }
`;

const TextWithEllipsis = styled(Text)`
  width: calc(100% - 5rem);
`;

export const SettingsOpenGraph: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;
  const atiraLink = useAppSelector(atiraLinkSliceSelectors.selectUserAtiraLink);

  const { control, setValue, getValues, watch, handleSubmit } =
    useForm<EditAtiraLinkDto>({
      defaultValues: {
        atiraLinkId: atiraLink?._id,
        settings: {
          openGraph: {
            title: atiraLink?.settings?.openGraph?.title || '',
            description: atiraLink?.settings?.openGraph?.description || '',
          },
        },
        ogImage: isString(atiraLink?.settings?.openGraph?.ogImage)
          ? getMediaUrl(atiraLink?.settings.openGraph.ogImage)
          : '',
        userId,
      },
    });

  const dispatch = useAppDispatch();

  const [title, description, image] = watch([
    'settings.openGraph.title',
    'settings.openGraph.description',
    'ogImage',
  ]);

  const onUpdateOpenGraph = async () => {
    try {
      setLoading(true);

      const dto = getValues();

      await dispatch(AtiraLinkActions.editAtiraLink(dto)).unwrap();
      await dispatch(AtiraLinkActions.getUserAtiraLink({ userId })).unwrap();

      AtiraToast.success(t('settings.open_graph.update.success'));
    } catch (e) {
      console.log(e);
      AtiraToast.apiError(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <SectionWrapper>
        <SectionTitle>{t('settings.open_graph.title')}</SectionTitle>

        <Section>
          <Controller
            control={control}
            name="ogImage"
            render={({ field: { onChange } }) => (
              <ImageUploadWrapper>
                <ImageUpload
                  maxCount={1}
                  onRemove={() => setValue('ogImage', '')}
                  onChange={onChange}
                  button={
                    <Tooltip
                      title={t('atira_link.create.content.image.tooltip')}
                    >
                      <Button
                        icon={faCamera}
                        borderRadius="pill"
                        color="main"
                        iconWidth="3x"
                        backgroundColor="transparent"
                      />
                    </Tooltip>
                  }
                  listType="picture-circle"
                  showUploadList={false}
                />
              </ImageUploadWrapper>
            )}
          />

          <Flex flexDirection="column" gap="m" width={'100%'}>
            <InputWrapper>
              <InputLabel>{t('common.title')}</InputLabel>

              <Controller
                control={control}
                name="settings.openGraph.title"
                render={({ field: { value, onChange } }) => (
                  <StyledInput
                    value={value}
                    onChange={onChange}
                    placeholder={t('common.title')}
                  />
                )}
              />
            </InputWrapper>
            <InputWrapper alignItems="flex-start">
              <InputLabel>{t('common.description')}</InputLabel>

              <Controller
                control={control}
                name="settings.openGraph.description"
                render={({ field: { value, onChange } }) => (
                  <StyledTextarea
                    value={value}
                    onChange={onChange}
                    placeholder={t('common.description')}
                  />
                )}
              />
            </InputWrapper>
          </Flex>
        </Section>
      </SectionWrapper>

      <PreviewWrapper>
        <PreviewInnerWrapper>
          <PreviewImageWrapper>
            <ImageCreateEdit src={image} />
          </PreviewImageWrapper>

          <Flex flexDirection="column" padding="s" width={'100%'}>
            <TextWithEllipsis fontWeight={'bold'} cutText>
              {title || t('common.title')}
            </TextWithEllipsis>

            <TextWithEllipsis color="subTextColor" cutText>
              {description || t('common.description')}
            </TextWithEllipsis>

            <Text fontSize="s" color="subTextColor">
              atrl.ink
            </Text>
          </Flex>
        </PreviewInnerWrapper>

        <Button
          loading={loading}
          onClick={handleSubmit(onUpdateOpenGraph)}
          hover
          hoverBackgroundColor="lightMain"
          height="2.5rem"
        >
          {t('common.save')}
        </Button>
      </PreviewWrapper>
    </Container>
  );
};
