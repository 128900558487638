import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraTabs } from '../../../components/AtiraTabs';
import { Drawer } from '../../../components/Drawer';
import { blogsActions } from '../../../redux/blogs/blogs.slice';
import { useAppDispatch } from '../../../redux/store';
import { Rounded } from '../../../theme/Rounded';
import { Spacing } from '../../../theme/Spacing';
import { NotificationsAdminMessagesTab } from './NotificationsAdminMessagesTab';
import { NotificationsBlogTab } from './NotificationsBlogTab';
import { NotificationsUpdatesTab } from './NotificationsUpdatesTab';

const StyledTabs = styled(AtiraTabs)`
  padding-inline: ${Spacing.m};

  .ant-tabs-tab {
    color: ${({ theme }) => theme.main} !important;
    border-radius: ${Rounded.sm};
    transition: all 0.2s !important;
    margin-inline: 0 !important;
    border-radius: ${Rounded.md};
    border: ${({ theme }) => `1px solid ${theme.main}`};
    padding: ${Spacing.s} ${Spacing.m} !important;
  }

  .ant-tabs-tab-btn {
    transition: all 0.2s !important;
    user-select: none;
    font-size: 1.25rem;
    line-height: normal !important;
  }

  .ant-tabs-tab-btn:active,
  .ant-tabs-tab-btn:focus:not(:focus-visible) {
    color: ${({ theme }) => theme.light} !important;
  }

  .ant-tabs-tab:not(.ant-tabs-tab-active):hover {
    color: ${({ theme }) => theme.light} !important;
    background-color: ${({ theme }) => theme.lightMain} !important;
  }

  .ant-tabs-ink-bar {
    display: none !important;
  }

  .ant-tabs-nav .ant-tabs-nav-wrap::before {
    box-shadow: none !important;
  }

  .ant-tabs-nav-list {
    gap: ${Spacing.m};
  }

  .ant-tabs-nav-list::after,
  .ant-tabs-nav .ant-tabs-nav-wrap::after {
    display: none !important;
  }

  .ant-tabs-nav {
    border: 0 !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active {
    background-color: ${({ theme }) => theme.main};
    color: ${({ theme }) => theme.light} !important;
  }

  .ant-tabs-tab-active:hover,
  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${({ theme }) => theme.light} !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: inherit !important;
  }

  //this fixes flickering issue
  .ant-tabs-nav-operations {
    display: none !important;
  }
`;

const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 0;
  }
`;

type NotificationsDrawerProps = {
  open: boolean;
  onClose: VoidFunction;
};

export const NotificationsDrawer: React.FC<NotificationsDrawerProps> = ({
  open,
  onClose,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(blogsActions.getBlogs());
  }, [dispatch]);

  return (
    <StyledDrawer
      title={t('header.bell.drawer.title')}
      onClose={onClose}
      open={open}
      width={'40rem'}
    >
      <StyledTabs
        defaultActiveKey="1"
        items={[
          {
            key: '1',
            label: t('header.bell.drawer.articles_news'),
            children: <NotificationsBlogTab />,
          },
          {
            key: '2',
            label: t('header.bell.drawer.products_updates'),
            children: <NotificationsUpdatesTab />,
          },
          {
            key: '3',
            label: t('header.bell.drawer.admin_messages'),
            children: <NotificationsAdminMessagesTab />,
          },
        ]}
      />
    </StyledDrawer>
  );
};
