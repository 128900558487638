import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AtiraTabs } from '../../components/AtiraTabs';
import { Flex } from '../../components/Flex';
import { UserHeader } from '../../components/UserHeader';
import { PageMeta } from '../../model/meta/PageMeta';
import { adminActions } from '../../redux/admin/admin.slice';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { userSliceSelectors } from '../../redux/user/user.selector';
import { AdminReportsTab } from './AdminReportsTab';
import { AdminSearchUsersTab } from './AdminSearchUserTab';
import { AdminStatsTab } from './AdminStatsTab';

export const AdminRoute: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;

  const onTabChange = (key: string) => {
    if (key === 'search') {
      dispatch(adminActions.searchUsers({ userId, meta: PageMeta.create() }));
    }

    if (key === 'reports') {
      dispatch(adminActions.getReports({ userId, meta: PageMeta.create() }));
    }

    if (key === 'stats') {
      dispatch(adminActions.getAdminStats({ userId }));
    }
  };

  useEffect(() => {
    dispatch(adminActions.getAdminStats({ userId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AtiraTabs
      onTabClick={onTabChange}
      items={[
        {
          key: 'stats',
          label: t('admin.header.title'),
          children: <AdminStatsTab />,
        },
        {
          key: 'search',
          label: t('common.search'),
          children: <AdminSearchUsersTab />,
        },
        {
          key: 'reports',
          label: t('common.reports'),
          children: <AdminReportsTab />,
        },
      ]}
      sideComponent={
        <Flex marginTop="s">
          <UserHeader />
        </Flex>
      }
    />
  );
};
