import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons/faEyeSlash';
import { t } from 'i18next';
import merge from 'lodash/merge';
import React, { InputHTMLAttributes, useState } from 'react';
import styled from 'styled-components';

import { useTheme } from '../ThemeContext';
import i18n, { AppLangs } from '../i18n';
import { Rounded } from '../theme/Rounded';
import { Spacing } from '../theme/Spacing';
import { ThemeType } from '../theme/Theme';
import { AtiraIcon } from './AtiraIcon';
import { Button } from './Button';
import { Flex } from './Flex';
import { Text } from './Text';
import {
  LabelDirection,
  TitleAlignment,
} from './shared/types/SharedLabelProps';

const ShowHidePasswordButton = styled(Button)`
  position: absolute;
  top: 25%;
  bottom: 25%;
  right: ${i18n.language === AppLangs.AR ? 'auto' : Spacing.m};
  left: ${i18n.language === AppLangs.AR ? Spacing.m : 'auto'};
  margin: 0;
  padding: 0;
  color: ${(props) => props.theme.main};
  background-color: ${({ theme }) => theme.transparent};
  height: auto;
`;

interface StyledInputProps {
  width?: string;
  height?: string;
  border?: string;
  borderRadius?: keyof typeof Rounded;
  padding?: string;
  margin?: string;
  color?: string;
}

const StyledInput = styled.input<StyledInputProps>`
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '2.3rem'};
  margin: ${({ margin }) => margin || '0'};
  border: ${({ border }) => border || '0'};
  border-radius: ${({ borderRadius, theme }) =>
    borderRadius || `1px solid ${theme.darkSub}`};
  color: ${({ theme }) => theme.textColor};
  transition: box-shadow 0.2s;
  outline: none !important;
  border-radius: ${Rounded.md};

  &[type='phone'],
  &[type='number'] {
    direction: ltr;
    &::placeholder {
      text-align: ${i18n.language === AppLangs.AR ? 'end' : 'start'};
    }
  }
  background-color: ${({ theme }) => theme.light};
`;

const StyledInputWrapper = styled(Flex)`
  gap: 0.2rem;
  align-items: center;
  border-radius: ${Rounded.md};
`;

const StyledLabel = styled.label`
  cursor: pointer;
  color: ${({ theme }) => theme.textColor};
  width: fit-content;
  flex: 1;
`;

const OptionalSpan = styled.span`
  color: ${({ theme }) => theme.subTextColor};
  display: inline-block;
  margin-inline-start: ${Spacing.s};
`;

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  title?: string;
  id?: string;
  valid?: boolean;
  width?: string;
  height?: string;
  border?: string;
  borderRadius?: keyof typeof Rounded;
  placeholder?: string;
  padding?: string;
  margin?: string;
  icon?: IconProp;
  errorMessage?: string;
  color?: string;
  titleAlign?: keyof typeof TitleAlignment;
  labelDirection?: keyof typeof LabelDirection;
  iconSize?: SizeProp;
  iconColor?: keyof ThemeType;
}

enum PasswordInputTypes {
  TEXT = 'TEXT',
  PASSWORD = 'PASSWORD',
}

export const Input: React.FC<InputProps> = (props) => {
  const { type, ...restProps } = props;

  const [inputType, setInputType] = useState(type);

  const { theme } = useTheme();

  const togglePasswordInputType = () => {
    setInputType(
      inputType === PasswordInputTypes.PASSWORD.toLowerCase()
        ? PasswordInputTypes.TEXT.toLowerCase()
        : PasswordInputTypes.PASSWORD.toLowerCase(),
    );
  };

  return (
    <Flex flexDirection="column" gap="s" width={props.width || '100%'}>
      <Flex
        width={'100%'}
        gap="s"
        flexDirection={
          props.labelDirection === LabelDirection.HORIZONTAL ? 'row' : 'column'
        }
      >
        {props.title ? (
          <StyledLabel
            htmlFor={props.id}
            style={{
              textAlign: props.titleAlign,
              alignSelf: props.titleAlign || 'flex-start',
              margin:
                props.labelDirection === LabelDirection.HORIZONTAL
                  ? 'auto'
                  : '0',
            }}
          >
            {props.title}

            {props.required ? (
              <span style={{ color: theme.danger }}> *</span>
            ) : (
              <OptionalSpan>({t('common.optional')})</OptionalSpan>
            )}
          </StyledLabel>
        ) : null}

        {props.icon ? (
          <Flex flexDirection="column" position="relative" gap="s" flex={2}>
            <StyledInputWrapper
              opacity={props.disabled ? '0.2' : '1'}
              border={
                props.valid === false
                  ? `1px solid ${theme.danger}`
                  : `1px solid ${theme.lightTextColor}`
              }
              borderRadius={props.borderRadius || 'md'}
              style={{ padding: `0 ${Spacing.s}` }}
            >
              <StyledInput
                type={inputType}
                style={props.style}
                placeholder={props.placeholder}
                {...restProps}
              />

              <AtiraIcon
                icon={props.icon}
                size={props.iconSize || 'lg'}
                color={props.iconColor || 'textColor'}
              />
            </StyledInputWrapper>

            {props.errorMessage && props.valid === false ? (
              <Text color="danger" fontSize="s">
                {props.errorMessage}
              </Text>
            ) : null}
          </Flex>
        ) : (
          <Flex flexDirection="column" position="relative" gap="s" flex={2}>
            <StyledInput
              type={inputType}
              style={merge(
                props.style,
                props.disabled ? { opacity: '0.2' } : {},
                props.valid === false
                  ? { border: `1px solid ${theme.danger}` }
                  : { border: `1px solid ${theme.darkSub}` },
                {
                  padding: props.padding || `${Spacing.o} ${Spacing.s}`,
                },
              )}
              placeholder={props.placeholder}
              {...restProps}
            />

            {type === PasswordInputTypes.PASSWORD.toLowerCase() ? (
              <ShowHidePasswordButton
                onClick={togglePasswordInputType}
                icon={
                  inputType === PasswordInputTypes.PASSWORD.toLowerCase()
                    ? faEye
                    : faEyeSlash
                }
              />
            ) : null}

            {props.errorMessage && props.valid === false ? (
              <Text color="danger" fontSize="s">
                {props.errorMessage}
              </Text>
            ) : null}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
