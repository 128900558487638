import { TFunction } from 'i18next';
import debounce from 'lodash/debounce';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraSelect } from '../../components/AtiraSelect';
import { AtiraTable } from '../../components/AtiraTable';
import { Flex } from '../../components/Flex';
import { Input } from '../../components/Input';
import { WarningModal } from '../../components/WarningModal';
import { SearchUsersDto } from '../../model/admin/dto/SearchUsersDto';
import { SearchUsersUserDto } from '../../model/admin/dto/SearchUsersUserDto';
import { CreationRange } from '../../model/admin/types/CreationRange.enum';
import { UserKind } from '../../model/user/types/UserKind.enum';
import { adminSliceSelectors } from '../../redux/admin/admin.selector';
import { adminActions } from '../../redux/admin/admin.slice';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { userSliceSelectors } from '../../redux/user/user.selector';
import { Breakpoints } from '../../theme/Breakpoints';
import { Spacing } from '../../theme/Spacing';
import { AtiraToast } from '../../utils/AtiraToast';
import { AdminMessageModal } from './components/AdminMessageModal';
import { createAdminSearchUsersTableColumns } from './components/createAdminSearchUsersTableColumns';

const getUserKindFilterOptions = (t: TFunction) => [
  { label: t('common.all_kinds'), value: null },
  { label: t(`common.${UserKind.FREE.toLowerCase()}`), value: UserKind.FREE },
  { label: t(`common.${UserKind.PAID.toLowerCase()}`), value: UserKind.PAID },
];

const getRangeFilterOptions = (t: TFunction) => [
  { label: t('common.all_times'), value: null },
  {
    label: t(`common.${CreationRange.TODAY.toLowerCase()}`),
    value: CreationRange.TODAY,
  },
  {
    label: t(`common.${CreationRange.YESTERDAY.toLowerCase()}`),
    value: CreationRange.YESTERDAY,
  },
  {
    label: t(`common.${CreationRange.LAST_WEEK.toLowerCase()}`),
    value: CreationRange.LAST_WEEK,
  },
  {
    label: t(`common.${CreationRange.LAST_MONTH.toLowerCase()}`),
    value: CreationRange.LAST_MONTH,
  },
];

const StyledSelect = styled(AtiraSelect)`
  height: 2.3rem;
  width: 8rem;
`;

const StyledInput = styled(Input)`
  @media (min-width: ${Breakpoints.TABLET}) {
    max-width: 15rem;
  }
`;

const FiltersWrapper = styled(Flex)`
  gap: ${Spacing.s};
  flex-wrap: wrap;

  @media (min-width: ${Breakpoints.TABLET}) {
    flex-wrap: nowrap;
    width: max-content;
  }
`;

export const AdminSearchUsersTab: React.FC = () => {
  const { t } = useTranslation();

  const { control, getValues, reset } = useForm<SearchUsersDto>();

  const dispatch = useAppDispatch();

  const [selectedUser, setSelectedUser] =
    useState<Partial<SearchUsersUserDto> | null>(null);

  const [
    toggleUserStatusUserModalVisible,
    settoggleUserStatusUserModalVisible,
  ] = useState(false);

  const [enableDisableLoading, setEnableDisableLoading] = useState(false);
  const [sendMessageToUserModalVisible, setSendMessageToUserModalVisible] =
    useState(false);

  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;
  const searchUsers = useAppSelector(adminSliceSelectors.selectSearchUsers);
  const searchUsersMeta = useAppSelector(
    adminSliceSelectors.selectSearchUsersMeta,
  );
  const searchUsersLoading = useAppSelector(
    adminSliceSelectors.searchUsersLoading,
  );
  const usersPage = useAppSelector(adminSliceSelectors.selectUsersTablePage);
  const usersPageSize = useAppSelector(
    adminSliceSelectors.selectUsersTablePageSize,
  );
  const onToggleUserStatus = (user: typeof selectedUser) => {
    setSelectedUser(user);
    settoggleUserStatusUserModalVisible(true);
  };

  const onSendMessageToUser = (user: typeof selectedUser) => {
    setSelectedUser(user);
    setSendMessageToUserModalVisible(true);
  };

  const tableColumns = createAdminSearchUsersTableColumns<SearchUsersUserDto>({
    t,
    toggleStatus: onToggleUserStatus,
    sendMessage: onSendMessageToUser,
  });

  const onPageChange = async (page: number, pageSize: number) => {
    dispatch(adminActions.setUsersTablePage(page));
  };

  const onTableShowSize = (curr: number, newSize: number) => {
    dispatch(adminActions.setUsersTablePageSize(newSize));
    onPageChange(1, newSize);
  };
  const enableDisableUser = async () => {
    try {
      setEnableDisableLoading(true);

      await dispatch(
        adminActions.toggleUserStatus({ userId: selectedUser?.user?._id! }),
      ).unwrap();

      fetchUsers();

      AtiraToast.success(
        t(
          `admin.user.${selectedUser?.user?.enabled === false ? 'enable' : 'disable'}.success`,
          { name: selectedUser?.user?.name || selectedUser?.user?.email },
        ),
      );
      settoggleUserStatusUserModalVisible(false);
    } catch (e: any) {
      console.log(e);
      AtiraToast.apiError(e);
    } finally {
      setEnableDisableLoading(false);
    }
  };

  const fetchUsers = useCallback(
    () =>
      dispatch(
        adminActions.searchUsers({
          userId,
          meta: {
            count: usersPageSize,
            page: usersPage - 1,
          },
          ...omitBy(getValues(), isNil),
        }),
      ),
    [dispatch, getValues, userId, usersPage, usersPageSize],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(debounce(fetchUsers, 1000), [fetchUsers]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  useEffect(() => {
    reset({
      keyword: '',
      kind: undefined,
      range: undefined,
      userId,
    });
  }, [reset, userId]);

  return (
    <Flex flexDirection="column" gap="m" flex={1}>
      <FiltersWrapper>
        <Controller
          control={control}
          name="keyword"
          render={({ field: { value, onChange } }) => (
            <StyledInput
              value={value}
              onChange={(e) => {
                onChange(e.target.value);
                debouncedSearch();
              }}
              placeholder={t('common.search')}
            />
          )}
        />

        <Controller
          control={control}
          name="kind"
          render={({ field: { value, onChange } }) => (
            <StyledSelect
              options={getUserKindFilterOptions(t)}
              defaultValue={getUserKindFilterOptions(t)[0]}
              value={value}
              onChange={(e) => {
                onChange(e);
                fetchUsers();
              }}
            />
          )}
        />

        <Controller
          control={control}
          name="range"
          render={({ field: { value, onChange } }) => (
            <StyledSelect
              options={getRangeFilterOptions(t)}
              defaultValue={getRangeFilterOptions(t)[0]}
              value={value}
              onChange={(e) => {
                onChange(e);
                fetchUsers();
              }}
            />
          )}
        />
      </FiltersWrapper>

      <AtiraTable
        key={JSON.stringify(tableColumns)}
        columns={tableColumns}
        data={searchUsers}
        loading={searchUsersLoading}
        pagination={{
          pageSizeOptions: ['5', '10', '20', '50', '100'],
          pageSize: usersPageSize,
          current: usersPage,
          total: searchUsersMeta.total,
          onChange: onPageChange,
          showSizeChanger: true,
          onShowSizeChange: onTableShowSize,
        }}
        rowSelection={undefined}
        size="small"
        scroll={{ x: 'max-content' }}
      />

      <WarningModal
        title={t(
          `admin.user.${(selectedUser as any)?.enabled ? 'disable' : 'enable'}.modal.title`,
          { name: selectedUser?.user?.name || selectedUser?.user?.email },
        )}
        description={t(
          `admin.user.${(selectedUser as any)?.enabled ? 'disable' : 'enable'}.modal.description`,
          { name: selectedUser?.user?.name || selectedUser?.user?.email },
        )}
        open={toggleUserStatusUserModalVisible}
        onClose={() => settoggleUserStatusUserModalVisible(false)}
        onConfirm={enableDisableUser}
        loading={enableDisableLoading}
      />

      <AdminMessageModal
        user={selectedUser}
        open={sendMessageToUserModalVisible}
        onClose={() => setSendMessageToUserModalVisible(false)}
      />
    </Flex>
  );
};
