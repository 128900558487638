import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraIcon } from './AtiraIcon';
import { Button } from './Button';
import { Flex } from './Flex';
import { Text } from './Text';
import { Modal } from './modal/Modal';

const NoButton = styled(Button)`
  background-color: ${({ theme }) => theme.light};
  border: ${({ theme }) => `1px solid ${theme.textColor}`};
  height: 2.5rem;
  color: ${({ theme }) => theme.textColor};

  &:hover {
    color: ${({ theme }) => theme.light};
    background-color: ${({ theme }) => theme.textColor};
  }
`;

interface WarningModalProps {
  title?: string;
  description?: string;
  onConfirm?: VoidFunction;
  loading?: boolean;
  open: boolean;
  onClose?: VoidFunction;
}

export const WarningModal: React.FC<WarningModalProps> = ({
  title,
  description,
  onConfirm,
  onClose,
  loading,
  open,
}) => {
  const { t } = useTranslation();

  const onLocalConfirm = () => {
    onConfirm?.();
  };

  return (
    <Modal open={open} onClose={onClose} title={t('common.warning')}>
      <Flex flexDirection="column" alignItems="center" justifyContent="center">
        <Flex width={'4rem'}>
          <AtiraIcon icon={faTriangleExclamation} size="4x" color="textColor" />
        </Flex>

        <Text fontSize="xxl" align="center" color="textColor">
          {title ? title : t('common.are_you_sure')}
        </Text>

        <Text fontSize="m" align="center" color="subTextColor">
          {description ? description : t('forms.delete.modal.description')}
        </Text>

        <Flex gap="xl" marginTop="m">
          <NoButton onClick={onClose}>{t('common.no')}</NoButton>

          <Button
            hover
            hoverBackgroundColor="lightMain"
            width="5rem"
            height="2.5rem"
            loading={loading}
            onClick={onLocalConfirm}
          >
            {t('common.yes')}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};
