import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Textarea } from '../../../components/Textarea';
import { Modal } from '../../../components/modal/Modal';
import { AdminMessageUserDto } from '../../../model/admin/dto/AdminMessageUserDto';
import { SearchUsersUserDto } from '../../../model/admin/dto/SearchUsersUserDto';
import { Lengths } from '../../../model/shared/enum/Lengths.enum';
import { adminActions } from '../../../redux/admin/admin.slice';
import { useAppDispatch } from '../../../redux/store';
import { AtiraToast } from '../../../utils/AtiraToast';

type AdminMessageModalProps = {
  open: boolean;
  onClose: VoidFunction;
  user: Partial<SearchUsersUserDto> | null;
};

export const AdminMessageModal: React.FC<AdminMessageModalProps> = ({
  open = false,
  onClose,
  user,
}) => {
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const { control, getValues, handleSubmit, reset, watch } =
    useForm<AdminMessageUserDto>();

  const message = watch('message');

  const dispatch = useAppDispatch();

  const onSendMessage = async () => {
    try {
      setLoading(true);

      const dto = getValues();

      await dispatch(adminActions.sendMessageToUser(dto)).unwrap();

      AtiraToast.success(t('admin.user.message.success'));

      onClose();
    } catch (e: any) {
      console.log(e);
      AtiraToast.apiError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open && user) {
      reset({ userId: user.user?._id, message: '' });
    }
  }, [open, user, reset]);

  if (!user) {
    return null;
  }

  return (
    <Modal
      open={open}
      showCloseWarning={message?.length > 0}
      onClose={onClose}
      onConfirmClose={() => reset({ message: '', userId: undefined })}
      title={t('admin.user.message.modal.title', {
        name: user?.user?.name || user?.user?.email,
      })}
    >
      <Flex width={'100%'} flexDirection="column" gap="m">
        <Controller
          control={control}
          name="message"
          rules={{
            required: {
              value: true,
              message: t('common.this_field_required'),
            },
            maxLength: {
              value: Lengths.LONG,
              message: t('error.length.less', {
                name: t('common.message'),
                length: Lengths.LONG,
              }),
            },
          }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Textarea
              value={value}
              onChange={onChange}
              title={t('common.message')}
              valid={!error}
              errorMessage={error?.message}
              maxLength={Lengths.LONG}
            />
          )}
        />

        <Button
          onClick={handleSubmit(onSendMessage)}
          loading={loading}
          height="2.5rem"
        >
          {t('common.send')}
        </Button>
      </Flex>
    </Modal>
  );
};
