import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faTiktok } from '@fortawesome/free-brands-svg-icons/faTiktok';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons/faXTwitter';
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '../../../components/Button';
import { Drawer } from '../../../components/Drawer';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { atiraLinkSliceSelectors } from '../../../redux/nerve/atira-link.selector';
import { useAppSelector } from '../../../redux/store';

enum Socials {
  INSTAGRAM = 'INSTAGRAM',
  TWITTER = 'TWITTER',
  FACEBOOK = 'FACEBOOK',
  YOUTUBE = 'YOUTUBE',
  TIKTOK = 'TIKTOK',
}

const StyledButton = styled(Button)<{
  color?: string;
  backgroundColor?: string;
  socialName?: Socials;
}>`
  color: ${({ color }) => color};
  font-size: 1.2rem;
  margin: 0;
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: ${({ socialName }) =>
    socialName === Socials.FACEBOOK ? '0.2rem 0.7rem' : '0.2rem'};

  ${({ socialName }) =>
    socialName === Socials.INSTAGRAM
      ? `
        background: -moz-linear-gradient(
          45deg,
          #f09433 0%,
          #e6683c 25%,
          #dc2743 50%,
          #cc2366 75%,
          #bc1888 100%
        );
        background: -webkit-linear-gradient(
          45deg,
          #f09433 0%,
          #e6683c 25%,
          #dc2743 50%,
          #cc2366 75%,
          #bc1888 100%
        );
        background: linear-gradient(
          45deg,
          #f09433 0%,
          #e6683c 25%,
          #dc2743 50%,
          #cc2366 75%,
          #bc1888 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
    `
      : ''}

  &:hover {
    opacity: 0.7;
  }
`;

type ShareDrawerProps = {
  open: boolean;
  onClose: VoidFunction;
};

const socialMediaLinks = [
  {
    name: Socials.INSTAGRAM,
    icon: faInstagram,
    url: 'https://www.instagram.com/accounts/edit/',
    color: '#ffffff',
  },
  {
    name: Socials.TWITTER,
    icon: faXTwitter,
    url: 'https://twitter.com/settings/profile',
    color: '#000000',
    backgroundColor: '#ffffff',
  },
  {
    name: Socials.FACEBOOK,
    icon: faFacebookF,
    url: 'https://www.facebook.com/profile.php?sk=editprofile',
    color: '#ffffff',
    backgroundColor: '#4267B2',
  },
  {
    name: Socials.YOUTUBE,
    icon: faYoutube,
    url: 'https://www.youtube.com/account',
    color: '#ff0000',
    backgroundColor: '#ffffff',
  },
  {
    name: Socials.TIKTOK,
    icon: faTiktok,
    url: 'https://www.tiktok.com/settings',
    color: '#000000',
    backgroundColor: '#ffffff',
  },
];

export const ShareDrawer: React.FC<ShareDrawerProps> = ({ open, onClose }) => {
  const { t } = useTranslation();

  const userLink = useAppSelector(atiraLinkSliceSelectors.selectUserAtiraLink);
  const userLinkUrl = `https://atrl.ink/${userLink?.linkName}`;

  const copyLink = async (url: string) => {
    try {
      await navigator.clipboard.writeText(userLinkUrl);

      window.open(url, '_blank');
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Drawer
      title={t('header.share.drawer.title')}
      onClose={onClose}
      open={open}
      width="30rem"
    >
      <Flex gap="l" flexDirection="column">
        <Text color="main" fontSize="l">
          {t('header.share.drawer.paragraph')}
        </Text>
        <Flex gap="l" justifyContent="center">
          {socialMediaLinks.map((social) => (
            <StyledButton
              key={social.name}
              onClick={() => copyLink(social.url)}
              icon={social.icon}
              iconWidth="2x"
              backgroundColor={social.backgroundColor}
              color={social.color}
              socialName={social.name}
            />
          ))}
        </Flex>
      </Flex>
    </Drawer>
  );
};
