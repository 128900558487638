import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AtiraEmpty } from '../../../components/AtiraEmpty';
import { Flex } from '../../../components/Flex';
import { SpinnerFullScreen } from '../../../components/SpinnerFullScreen';
import { Text } from '../../../components/Text';
import { adminSliceSelectors } from '../../../redux/admin/admin.selector';
import { adminActions } from '../../../redux/admin/admin.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { userSliceSelectors } from '../../../redux/user/user.selector';
import { AdminMessage } from './AdminMessage';

export const NotificationsAdminMessagesTab: React.FC = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'header.bell.drawer.adminMessages',
  });
  const dispatch = useAppDispatch();

  const adminMessages = useAppSelector(adminSliceSelectors.selectAdminMessages);
  const adminMessagesLoading = useAppSelector(
    adminSliceSelectors.selectAdminMessagesLoading,
  );
  const adminMessagesPage = useAppSelector(
    adminSliceSelectors.selectAdminMessagesPage,
  );
  const adminMessagesPageSize = useAppSelector(
    adminSliceSelectors.selectAdminMessagesPageSize,
  );
  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;

  useEffect(() => {
    dispatch(
      adminActions.getAdminMessages({
        userId,
        meta: { page: adminMessagesPage - 1, count: adminMessagesPageSize },
      }),
    );
  }, [dispatch, userId]);

  if (adminMessagesLoading && !adminMessages?.length) {
    return <SpinnerFullScreen />;
  }

  return (
    <Flex flexDirection="column" gap="m">
      <Text>{t('title')}</Text>
      {adminMessages?.length ? (
        <Flex flexDirection="column" gap="s">
          {adminMessages.map((message) => (
            <AdminMessage key={message._id} message={message} />
          ))}
        </Flex>
      ) : (
        <AtiraEmpty description={t('empty')} />
      )}
    </Flex>
  );
};
