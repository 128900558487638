import { Flex } from 'antd';
import styled from 'styled-components';

import { Text } from '../../../components/Text';
import { AdminMessage as AdminMessageModel } from '../../../model/admin/AdminMessage';
import { Rounded } from '../../../theme/Rounded';
import { standardDate } from '../../../utils/Date';

const Container = styled(Flex)`
  padding: 0.7rem;
  border: 1px solid #e8e8e8;
  border-radius: ${Rounded.md};
  margin-bottom: 1.5rem;
  flex-direction: column;
`;

type AdminMessageProps = {
  message: AdminMessageModel;
};

export const AdminMessage: React.FC<AdminMessageProps> = ({ message }) => {
  return (
    <Container>
      <Text>-{message.message}</Text>
      <Text color="darkerSub">{standardDate(message.createdAt, true)}</Text>
    </Container>
  );
};
