import { CreateQRDto } from '../../model/qr/dto/CreateQRDto';
import { DeleteQRDto } from '../../model/qr/dto/DeleteQRDto';
import { GetUserQRsDto } from '../../model/qr/dto/GetUserQRsDto';
import { GetUserQRsResponseDto } from '../../model/qr/dto/GetUserQRsResponseDto';
import { AtiraApi } from '../AxiosClient';

export class QRAxios {
  static async CreateQR(dto: CreateQRDto) {
    return AtiraApi.post(`/qr/generate`, dto).then(({ data }) => data);
  }

  static async getUserQRs(dto: GetUserQRsDto) {
    return AtiraApi.post<GetUserQRsResponseDto>('/qr/get', dto).then(
      ({ data }) => data,
    );
  }

  static async deleteQR(dto: DeleteQRDto) {
    return AtiraApi.post(`/qr/${dto.qrId}/delete`, dto).then(
      ({ data }) => data,
    );
  }
}
