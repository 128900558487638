import { createSlice } from '@reduxjs/toolkit';

import { QRAxios } from '../../axios/qr/qr.axios';
import { PageDto } from '../../model/meta/PageDto';
import { AtiraQR } from '../../model/qr/AtiraQR';
import { CreateQRDto } from '../../model/qr/dto/CreateQRDto';
import { DeleteQRDto } from '../../model/qr/dto/DeleteQRDto';
import { GetUserQRsDto } from '../../model/qr/dto/GetUserQRsDto';
import { GetUserQRsResponseDto } from '../../model/qr/dto/GetUserQRsResponseDto';
import { AtiraThunk } from '../AtiraThunk';

interface QRReducer {
  userQRs: AtiraQR[];
  userQRsMeta?: PageDto;
}

const createQR = AtiraThunk<void, CreateQRDto>(`/qr`, (dto) =>
  QRAxios.CreateQR(dto),
);

const getUserQRs = AtiraThunk<GetUserQRsResponseDto, GetUserQRsDto>(
  `/qr/get`,
  (dto) => QRAxios.getUserQRs(dto),
);

const deleteQR = AtiraThunk<void, DeleteQRDto>(`/qr/delete`, (dto) =>
  QRAxios.deleteQR(dto),
);

const initialState = Object.freeze<QRReducer>({
  userQRs: [],
  userQRsMeta: undefined,
});

const qrSlice = createSlice({
  name: 'QRSlice',
  initialState,
  reducers: {},
  extraReducers: ({ addCase }) => {
    addCase(getUserQRs.fulfilled, (state, action) => {
      state.userQRs = action.payload.data;
      state.userQRsMeta = action.payload.meta;
    });
  },
});

export const QRActions = {
  createQR,
  getUserQRs,
  deleteQR,
};

export default qrSlice.reducer;
