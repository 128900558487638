import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook';
import { faGithub } from '@fortawesome/free-brands-svg-icons/faGithub';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin';
import { faPinterest } from '@fortawesome/free-brands-svg-icons/faPinterest';
import { faReddit } from '@fortawesome/free-brands-svg-icons/faReddit';
import { faSnapchat } from '@fortawesome/free-brands-svg-icons/faSnapchat';
import { faSpotify } from '@fortawesome/free-brands-svg-icons/faSpotify';
import { faTelegram } from '@fortawesome/free-brands-svg-icons/faTelegram';
import { faTiktok } from '@fortawesome/free-brands-svg-icons/faTiktok';
import { faTwitch } from '@fortawesome/free-brands-svg-icons/faTwitch';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons/faWhatsapp';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons/faXTwitter';
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube';
import { faCamera } from '@fortawesome/free-solid-svg-icons/faCamera';
import { faLink } from '@fortawesome/free-solid-svg-icons/faLink';
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { Tooltip } from 'antd';
import React, { useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useTheme } from '../../../ThemeContext';
import { AtiraIcon } from '../../../components/AtiraIcon';
import { AtiraSelect } from '../../../components/AtiraSelect';
import { Button } from '../../../components/Button';
import { CopyButton } from '../../../components/CopyButton';
import { Flex } from '../../../components/Flex';
import { ImageUpload } from '../../../components/ImageUpload';
import { Input } from '../../../components/Input';
import { Text } from '../../../components/Text';
import { TextWithTruncated } from '../../../components/TextWithTruncated';
import { Textarea } from '../../../components/Textarea';
import { CreateAtiraLinkDto } from '../../../model/atira-link/dto/CreateAtiraLinkDto';
import type { AtiraLinkSocialHandlers } from '../../../model/atira-link/types/AtiraLinkSocialMediaHandlers.enum';
import { Lengths } from '../../../model/shared/enum/Lengths.enum';
import { atiraLinkSliceSelectors } from '../../../redux/nerve/atira-link.selector';
import { useAppSelector } from '../../../redux/store';
import { Breakpoints } from '../../../theme/Breakpoints';
import { Rounded } from '../../../theme/Rounded';
import { Spacing } from '../../../theme/Spacing';

const socialMediaIcons = {
  WHATSAPP: faWhatsapp,
  INSTAGRAM: faInstagram,
  TWITTER: faXTwitter,
  FACEBOOK: faFacebook,
  YOUTUBE: faYoutube,
  TELEGRAM: faTelegram,
  TIKTOK: faTiktok,
  SNAPCHAT: faSnapchat,
  REDDIT: faReddit,
  LINKEDIN: faLinkedin,
  PINTEREST: faPinterest,
  SPOTIFY: faSpotify,
  TWITCH: faTwitch,
  GITHUB: faGithub,
};

const placeholders: Record<AtiraLinkSocialHandlers, string> = {
  WHATSAPP: 'https://wa.me/905...',
  INSTAGRAM: 'https://www.instagram.com/...',
  TWITTER: 'https://www.twitter.com/...',
  FACEBOOK: 'https://www.facebook.com/...',
  YOUTUBE: 'https://www.youtube.com/...',
  TELEGRAM: 'https://www.telegram.com/...',
  TIKTOK: 'https://www.tiktok.com/...',
  SNAPCHAT: 'https://www.snapchat.com/...',
  REDDIT: 'https://www.reddit.com/...',
  LINKEDIN: 'https://www.linkedin.com/...',
  PINTEREST: 'https://www.pinterest.com/...',
  SPOTIFY: 'https://www.spotify.com/...',
  TWITCH: 'https://www.twitch.tv/...',
  GITHUB: 'https://www.github.com/...',
};

const getPlaceholder = (value: AtiraLinkSocialHandlers): string => {
  return placeholders[value] || '';
};

const SectionWrapper = styled(Flex)`
  flex-direction: column;
  gap: ${Spacing.s};
`;

const SectionTitle = styled(Text)`
  color: ${({ theme }) => theme.textColor};
  font-weight: bold;
  font-size: 1.3rem;
`;

const Section = styled(Flex)`
  background-color: ${({ theme }) => theme.light};
  border-radius: ${Rounded.lg};
  padding: ${Spacing.m};
  flex-direction: column;
  gap: ${Spacing.s};

  @media (min-width: ${Breakpoints.DESKTOP}) {
    gap: ${Spacing.m};
  }
`;

const InputWrapper = styled(Flex)<{ error?: boolean }>`
  border-radius: ${Rounded.md};
  padding-block: 0.2rem;
  flex-direction: column;
  gap: ${Spacing.s};

  @media (min-width: ${Breakpoints.DESKTOP}) {
    flex-direction: row;
    align-items: center;
    gap: 0;
    background-color: ${({ theme }) => theme.darkSub};
    padding-inline-start: ${Spacing.s};
    border: ${({ theme, error }) =>
      error ? `1px solid ${theme.danger}` : '0'};
  }
`;

const StyledInput = styled(Input)<{ error?: boolean }>`
  background-color: ${({ theme }) => theme.darkSub};
  font-size: 0.8rem;
  font-weight: 600;

  border: ${({ theme, error }) =>
    error ? `1px solid ${theme.danger}` : '0'} !important;

  @media (min-width: ${Breakpoints.TABLET}) {
    font-size: 1rem;
  }

  @media (min-width: ${Breakpoints.DESKTOP}) {
    background-color: ${({ theme }) => theme.transparent};
    border: 0;
  }
`;

const InputLabel = styled(Text)`
  color: ${({ theme }) => theme.main};
  font-size: 1rem;
  font-weight: 600;
  white-space: nowrap;

  @media (min-width: ${Breakpoints.TABLET}) {
    font-size: 1.2rem;
  }
`;

const StyledTextarea = styled(Textarea)`
  background-color: ${({ theme }) => theme.darkSub};
  border: 0;
  font-size: 0.8rem;
  font-weight: 600;
  resize: vertical;
  padding: 0 0.5rem !important;

  @media (min-width: ${Breakpoints.TABLET}) {
    font-size: 1rem;
  }

  @media (min-width: ${Breakpoints.DESKTOP}) {
    background-color: ${({ theme }) => theme.transparent};
  }
`;

const SocialsCustomLinksWrapper = styled(Flex)`
  flex: 1;
  flex-direction: column;
  gap: ${Spacing.s};
  margin-top: ${Spacing.s};
`;

const SocialCustomLinkHandleWrapper = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: ${Spacing.s};
  flex-wrap: wrap;
  border-bottom: ${({ theme }) => `2px solid ${theme.black}`};
  padding-bottom: ${Spacing.s};

  @media (min-width: ${Breakpoints.TABLET}) {
    flex-wrap: nowrap;
    border-bottom: 0;
    padding-bottom: 0;
  }
`;

const ImageUploadWrapper = styled(Flex)`
  .ant-upload-wrapper {
    border: ${({ theme }) => `1px solid ${theme.main}`} !important;
    border-radius: ${Rounded.circle} !important;
  }

  .ant-upload-select {
    border-radius: ${Rounded.circle} !important;
    border: 0 !important;
    overflow: hidden !important;
  }

  .ant-upload-list-picture-card {
    display: block !important;
    position: relative !important;
  }

  .ant-upload-list-item {
    border: 0 !important;
    padding: 0 !important;
    border-radius: ${Rounded.circle} !important;
    overflow: hidden !important;
  }

  .ant-upload-list-item-container {
    position: absolute !important;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }

  .ant-upload-list-item-actions {
    z-index: 5000 !important;
  }

  .ant-upload-list-item::before {
    width: 100% !important;
    height: 100% !important;
  }

  button {
    width: 100%;
    height: 100%;

    &:hover {
      background-color: ${({ theme }) => theme.lightMain} !important;
    }
  }
`;

const SocialCreationWrapper = styled(Flex)`
  flex-direction: column;
  gap: ${Spacing.s};
  flex: 1;

  @media (min-width: ${Breakpoints.DESKTOP}) {
    gap: ${Spacing.m};
  }
`;

const StyledButton = styled(CopyButton)`
  font-size: 0.6rem;
  padding: ${Spacing.s};
  margin: 0rem;
`;

const SocialsInputWrapper = styled(InputWrapper)`
  flex-grow: 1;
  padding-block: 0;

  @media (min-width: ${Breakpoints.DESKTOP}) {
    height: 2.3rem;
    border: ${({ theme }) => `2px solid ${theme.darkSub}`};
  }
`;

const RowTwoInputs = styled(Flex)`
  gap: ${Spacing.s};
  justify-content: space-between;
  flex-wrap: wrap;
  & > div {
    width: 100% !important;
  }

  @media (min-width: ${Breakpoints.DESKTOP}) {
    flex-wrap: nowrap;
    gap: ${Spacing.l};

    & > div {
      width: 50% !important;
    }
  }
`;

const ProfileInputsWrapper = styled(Flex)`
  align-items: center;
  gap: ${Spacing.s};
  flex-wrap: wrap;
  justify-content: center;

  @media (min-width: ${Breakpoints.DESKTOP}) {
    flex-wrap: nowrap;
    gap: ${Spacing.m};
  }
`;

const CustomLinksSection = styled(Section)`
  width: 100%;
  gap: ${Spacing.l};
  flex-wrap: wrap;

  @media (min-width: ${Breakpoints.DESKTOP}) {
    gap: ${Spacing.l};
  }
`;

const CustomLinkWrapper = styled(Flex)`
  flex-direction: column;
  width: 100%;

  & > div:first-child {
    flex-direction: column;
  }

  @media (min-width: ${Breakpoints.DESKTOP}) {
    & > div:first-child {
      flex-direction: row;
    }
  }
`;

const SocialHandleLinkWrapper = styled(Flex)`
  align-items: center;
  gap: ${Spacing.s};
  width: 100%;
  flex-wrap: wrap;
`;

const SocialLinkButtonWrapper = styled(Flex)`
  gap: ${Spacing.s};
  flex-direction: column;

  button {
    max-width: 5rem;
  }

  @media (min-width: ${Breakpoints.DESKTOP}) {
    flex-direction: row;
  }
`;

const CustomLinkInputWrapper = styled(InputWrapper)`
  flex: 1;
  padding-block: 0;

  @media (min-width: ${Breakpoints.DESKTOP}) {
    padding-inline: ${Spacing.s};
  }
`;

const CustomLinkInputsWrapper = styled(Flex)`
  gap: ${Spacing.s};
  flex-direction: column;

  @media (min-width: ${Breakpoints.DESKTOP}) {
    flex-direction: row;
  }
`;

const AddCustomLinkButton = styled(Button)`
  height: 2.3rem;
  margin-top: auto;

  width: fit-content;
`;

export const AtiraLinkContentTab: React.FC = () => {
  const [selectedOption, setSelectedOption] =
    useState<AtiraLinkSocialHandlers | null>(null);
  const [socialLink, setSocialLink] = useState('');
  const [socialLabel, setSocialLabel] = useState('');
  const [customLinkLabel, setCustomLinkLabel] = useState('');
  const [customLinkURL, setCustomLinkURL] = useState('');
  const [customLinkLabelError, setCustomLinkLabelError] = useState(false);
  const [customLinkURLError, setCustomLinkURLError] = useState(false);

  const aitralink = useAppSelector(atiraLinkSliceSelectors.selectUserAtiraLink);
  const userLinkUrl = `https://atrl.ink/${aitralink?.linkName}`;

  const { t } = useTranslation();
  const { theme } = useTheme();
  const { control, watch, setValue } = useFormContext<CreateAtiraLinkDto>();

  const { append, remove } = useFieldArray({
    control,
    name: 'socials',
  });

  const {
    fields: customLinks,
    remove: removeCustomLink,
    append: appendCustomLink,
  } = useFieldArray({
    control,
    name: 'customLinks',
  });

  const [socials, linkName] = watch(['socials', 'linkName']);

  const editMode = Boolean(aitralink?._id);

  const onAddSocial = () => {
    if (selectedOption && socialLink.trim() && socialLabel.trim()) {
      append({ handle: selectedOption, label: socialLabel, url: socialLink });
      setSelectedOption(null);
      setSocialLink('');
      setSocialLabel('');
    }
  };

  const onAddCustomLink = () => {
    if (!customLinkLabel.trim()) {
      setCustomLinkLabelError(true);
    } else {
      setCustomLinkLabelError(false);
    }

    if (!customLinkURL.trim()) {
      setCustomLinkURLError(true);
    } else {
      setCustomLinkURLError(false);
    }

    if (customLinkLabel.trim() && customLinkURL.trim()) {
      appendCustomLink({ label: customLinkLabel, value: customLinkURL });
      setCustomLinkLabel('');
      setCustomLinkURL('');
    }
  };

  const onRemoveCustomLink = (index: number) => {
    removeCustomLink(index);
    setCustomLinkLabelError(false);
    setCustomLinkURLError(false);
  };

  const getSocialMediaOptions = () =>
    (
      [
        { value: 'WHATSAPP', label: t('common.whatsapp') },
        { value: 'INSTAGRAM', label: t('common.instagram') },
        { value: 'TWITTER', label: t('common.twitter') },
        { value: 'FACEBOOK', label: t('common.facebook') },
        { value: 'YOUTUBE', label: t('common.youtube') },
        { value: 'TELEGRAM', label: t('common.telegram') },
        { value: 'TIKTOK', label: t('common.tiktok') },
        { value: 'SNAPCHAT', label: t('common.snapchat') },
        { value: 'REDDIT', label: t('common.reddit') },
        { value: 'LINKEDIN', label: t('common.linkedin') },
        { value: 'PINTEREST', label: t('common.pinterest') },
        { value: 'SPOTIFY', label: t('common.spotify') },
        { value: 'TWITCH', label: t('common.twitch') },
        { value: 'GITHUB', label: t('common.github') },
      ] as { value: AtiraLinkSocialHandlers; label: string }[]
    ).filter((item) => !socials?.map((s) => s.handle)?.includes(item.value));

  return (
    <Flex flexDirection="column" gap="l" flex={1}>
      <SectionWrapper>
        <SectionTitle>{t('common.link')}</SectionTitle>

        <Section gap="s">
          <Text color="subTextColor">
            {t(
              `atira_link.create.content.link_name.desdcription${editMode ? '.edit' : ''}`,
            )}
            <span style={{ fontWeight: 'bold' }}> https://atrl.ink/</span>
            <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
              {linkName}
            </span>
            <StyledButton copy={userLinkUrl} />
          </Text>

          <InputWrapper>
            <InputLabel>
              <span style={{ color: theme.danger }}>* </span>
              {t('atira_link.create.content.link_name')}:
            </InputLabel>

            <Controller
              control={control}
              name="linkName"
              rules={{
                required: true,
                minLength: {
                  value: Lengths.MIN_3,
                  message: t('error.range', {
                    name: t('atira_link.create.content.link_name'),
                    min: Lengths.MIN_3,
                    max: Lengths.NAME,
                  }),
                },
                maxLength: {
                  value: Lengths.NAME,
                  message: t('error.range', {
                    name: t('atira_link.create.content.link_name'),
                    min: Lengths.MIN_3,
                    max: Lengths.NAME,
                  }),
                },
              }}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <StyledInput
                  value={value}
                  onChange={(e) =>
                    onChange(e.target.value.toLowerCase().trim())
                  }
                  minLength={Lengths.MIN_3}
                  maxLength={Lengths.NAME}
                  errorMessage={error?.message}
                  valid={!error}
                  disabled={editMode}
                  placeholder={t(
                    'atira_link.create.content.link_name.length.note',
                  )}
                />
              )}
            />
          </InputWrapper>
        </Section>
      </SectionWrapper>

      <SectionWrapper>
        <SectionTitle>{t('common.profile')}</SectionTitle>

        <Section>
          <ProfileInputsWrapper>
            <Controller
              control={control}
              name="image"
              render={({ field: { onChange } }) => (
                <ImageUploadWrapper>
                  <ImageUpload
                    maxCount={1}
                    onRemove={() => setValue('image', '')}
                    onChange={onChange}
                    button={
                      <Tooltip
                        title={t('atira_link.create.content.image.tooltip')}
                      >
                        <Button
                          icon={faCamera}
                          borderRadius="pill"
                          color="main"
                          iconWidth="3x"
                          backgroundColor="transparent"
                        />
                      </Tooltip>
                    }
                    listType="picture-card"
                    showUploadList={false}
                  />
                </ImageUploadWrapper>
              )}
            />

            <Flex flexDirection="column" gap="m" width={'100%'}>
              <InputWrapper>
                <InputLabel>
                  <span style={{ color: theme.danger }}>* </span>
                  {t('common.title')}:
                </InputLabel>

                <Controller
                  control={control}
                  name="title"
                  rules={{
                    required: true,
                    minLength: {
                      value: Lengths.MIN_3,
                      message: t('error.range', {
                        name: t('common.title'),
                        min: Lengths.MIN_3,
                        max: Lengths.PHONE,
                      }),
                    },
                    maxLength: {
                      value: Lengths.NAME,
                      message: t('error.range', {
                        name: t('common.title'),
                        min: Lengths.MIN_3,
                        max: Lengths.NAME,
                      }),
                    },
                  }}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <StyledInput
                      value={value}
                      onChange={onChange}
                      placeholder={t(
                        'atira_link.create.content.title.placeholder',
                      )}
                      errorMessage={error?.message}
                      valid={!error}
                      maxLength={Lengths.NAME}
                    />
                  )}
                />
              </InputWrapper>

              <InputWrapper alignItems="flex-start">
                <InputLabel>{t('common.text')}:</InputLabel>

                <Controller
                  control={control}
                  name="bio"
                  render={({ field: { value, onChange } }) => (
                    <StyledTextarea
                      value={value}
                      onChange={onChange}
                      placeholder={t(
                        'atira_link.create.content.text.placeholder',
                      )}
                      maxLength={Lengths.DESCRIPTION}
                    />
                  )}
                />
              </InputWrapper>
            </Flex>
          </ProfileInputsWrapper>
        </Section>
      </SectionWrapper>

      <SectionWrapper>
        <SectionTitle>{t('common.basic')}</SectionTitle>

        <Section>
          <Flex flexDirection="column" gap="m">
            <RowTwoInputs>
              <InputWrapper width={'50%'}>
                <InputLabel>{t('common.phone')}:</InputLabel>

                <Controller
                  control={control}
                  name="phone"
                  render={({ field: { value, onChange } }) => (
                    <StyledInput
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      type="phone"
                      placeholder={'+0123456789'}
                      maxLength={Lengths.PHONE}
                    />
                  )}
                />
              </InputWrapper>

              <InputWrapper width={'50%'}>
                <InputLabel>{t('common.email')}:</InputLabel>

                <Controller
                  control={control}
                  name="email"
                  render={({ field: { value, onChange } }) => (
                    <StyledInput
                      value={value}
                      onChange={onChange}
                      type="email"
                      placeholder={'any@gmail.com'}
                      color="main"
                      maxLength={Lengths.EMAIL}
                    />
                  )}
                />
              </InputWrapper>
            </RowTwoInputs>

            <RowTwoInputs>
              <InputWrapper width={'50%'}>
                <InputLabel>{t('common.website')}:</InputLabel>
                <Controller
                  control={control}
                  name="website"
                  render={({ field: { value, onChange } }) => (
                    <StyledInput
                      value={value}
                      onChange={onChange}
                      placeholder="https://yourdomain.com"
                      maxLength={Lengths.DESCRIPTION}
                    />
                  )}
                />
              </InputWrapper>

              <InputWrapper width={'50%'}>
                <InputLabel>{t('common.map')}:</InputLabel>
                <Controller
                  control={control}
                  name="map"
                  render={({ field: { value, onChange } }) => (
                    <StyledInput
                      value={value}
                      onChange={onChange}
                      placeholder="https://maps.app.goo.gl/..."
                      maxLength={Lengths.DESCRIPTION}
                    />
                  )}
                />
              </InputWrapper>
            </RowTwoInputs>
          </Flex>
        </Section>
      </SectionWrapper>

      <SectionWrapper>
        <SectionTitle>{t('common.custom_links')}</SectionTitle>

        <CustomLinksSection>
          <CustomLinkWrapper>
            <Text>
              {t('atira_link.create.content.custom-links.label.info')}
            </Text>

            <CustomLinkInputsWrapper>
              <CustomLinkInputWrapper error={customLinkLabelError}>
                <InputLabel>
                  {t('atira_link.create.content.custom-links.label')}:
                </InputLabel>

                <StyledInput
                  error={customLinkLabelError}
                  value={customLinkLabel}
                  onChange={(e) => setCustomLinkLabel(e.currentTarget.value)}
                  placeholder={t(
                    'atira_link.create.content.social_label.plcaeholder',
                  )}
                  maxLength={Lengths.NAME}
                />
              </CustomLinkInputWrapper>

              <CustomLinkInputWrapper error={customLinkURLError}>
                <InputLabel>
                  {t('atira_link.create.content.custom-links.value')}:
                </InputLabel>

                <StyledInput
                  error={customLinkURLError}
                  value={customLinkURL}
                  onChange={(e) => setCustomLinkURL(e.currentTarget.value)}
                  placeholder="https://yourlink.com"
                  maxLength={Lengths.DESCRIPTION}
                />
              </CustomLinkInputWrapper>

              <Tooltip title={t('atira_link.create.custom_link.add')}>
                <AddCustomLinkButton
                  icon={faPlus}
                  onClick={onAddCustomLink}
                  disabled={customLinks && customLinks.length >= 2}
                />
              </Tooltip>
            </CustomLinkInputsWrapper>

            {customLinks?.length ? (
              <SocialsCustomLinksWrapper>
                <Text fontWeight={'bold'} fontSize="xm">
                  {t('atira_link.create.custom_link.custom_links.title')}
                </Text>

                {customLinks.map((item, index) => (
                  <SocialCustomLinkHandleWrapper key={item.id}>
                    <SocialHandleLinkWrapper>
                      <AtiraIcon icon={faLink} size="xl" color="textColor" />

                      <TextWithTruncated
                        length={15}
                        textProps={{ fontWeight: '600' }}
                      >
                        {item.label}
                      </TextWithTruncated>

                      <TextWithTruncated length={25}>
                        {item.value}
                      </TextWithTruncated>
                    </SocialHandleLinkWrapper>

                    <Tooltip
                      title={t('atira_link.create.custom_link.remove.tooltip')}
                    >
                      <Button
                        height="2.3rem"
                        onClick={() => onRemoveCustomLink(index)}
                        icon={faMinus}
                      />
                    </Tooltip>
                  </SocialCustomLinkHandleWrapper>
                ))}
              </SocialsCustomLinksWrapper>
            ) : null}
          </CustomLinkWrapper>
        </CustomLinksSection>
      </SectionWrapper>

      <SectionWrapper>
        <SectionTitle>{t('common.social_media')}</SectionTitle>

        <Section>
          <SocialCreationWrapper>
            <Flex
              justifyContent="space-between"
              alignItems="flex-end"
              gap="m"
              flexWrap="wrap"
            >
              <AtiraSelect
                title={t('atira_link.create.content.select_social')}
                value={{
                  label: selectedOption
                    ? t(`common.${selectedOption?.toLowerCase()}`)
                    : t('common.dropdown.select'),

                  value: selectedOption,
                }}
                options={getSocialMediaOptions()}
                onChange={(v) =>
                  setSelectedOption(v as AtiraLinkSocialHandlers)
                }
                containerStyle={{ width: '12rem' }}
              />

              <SocialsInputWrapper>
                <InputLabel>
                  {t('atira_link.create.content.social_label.title')}:
                </InputLabel>

                <StyledInput
                  id="social-label"
                  value={socialLabel}
                  placeholder={t(
                    'atira_link.create.content.social_label.plcaeholder',
                  )}
                  onChange={(e) =>
                    setSocialLabel(e.currentTarget.value.toUpperCase())
                  }
                  maxLength={Lengths.NAME}
                />
              </SocialsInputWrapper>
            </Flex>

            <SocialLinkButtonWrapper>
              <SocialsInputWrapper>
                <InputLabel>{t('common.enter_your_url')}:</InputLabel>

                <StyledInput
                  id="social-url"
                  value={socialLink}
                  placeholder={
                    selectedOption ? getPlaceholder(selectedOption) : ''
                  }
                  onChange={(e) => setSocialLink(e.currentTarget.value)}
                  maxLength={Lengths.DESCRIPTION}
                />
              </SocialsInputWrapper>

              <Tooltip title={t('atira_link.create.socials.add.tooltip')}>
                <Button
                  icon={faPlus}
                  onClick={onAddSocial}
                  disabled={!selectedOption || !socialLink || !socialLabel}
                />
              </Tooltip>
            </SocialLinkButtonWrapper>
          </SocialCreationWrapper>

          {socials?.length ? (
            <SocialsCustomLinksWrapper>
              <Text fontWeight={'bold'} fontSize="xm">
                {t('atira_link.create.socials.second_title')}
              </Text>
              {socials.map((item, index) => (
                <SocialCustomLinkHandleWrapper key={`${item.label}-${index}`}>
                  <SocialHandleLinkWrapper>
                    <AtiraIcon
                      icon={socialMediaIcons[item.handle]}
                      size="2x"
                      color="textColor"
                    />

                    <TextWithTruncated
                      length={15}
                      textProps={{ fontWeight: '600' }}
                    >
                      {item.label}
                    </TextWithTruncated>

                    <TextWithTruncated length={25}>
                      {item.url}
                    </TextWithTruncated>
                  </SocialHandleLinkWrapper>

                  <Tooltip
                    title={t('atira_link.create.socials.remove.tooltip')}
                  >
                    <Button
                      height="2rem"
                      onClick={() => remove(index)}
                      icon={faMinus}
                    />
                  </Tooltip>
                </SocialCustomLinkHandleWrapper>
              ))}
            </SocialsCustomLinksWrapper>
          ) : null}
        </Section>
      </SectionWrapper>
    </Flex>
  );
};
