import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';

const atiraLinkState = (state: RootState) => state.atiraLink;

const selectUserAtiraLink = createSelector(
  atiraLinkState,
  (state) => state.atiraLink,
);

const selectUserAtiraLinkLoading = createSelector(
  atiraLinkState,
  (state) => state.atiraLinkLoading,
);

const selectUserAtiraLinkStats = createSelector(
  atiraLinkState,
  (state) => state.atiraLinkStats,
);

export const atiraLinkSliceSelectors = {
  selectUserAtiraLink,
  selectUserAtiraLinkLoading,
  selectUserAtiraLinkStats,
};
