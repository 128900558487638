import { Tabs, TabsProps } from 'antd';
import React from 'react';
import styled from 'styled-components';

import { Breakpoints } from '../theme/Breakpoints';
import { Rounded } from '../theme/Rounded';
import { Spacing } from '../theme/Spacing';

const StyledTabs = styled(Tabs)`
  padding-inline: ${Spacing.m};
  width: 100%;

  .ant-table-cell:not(:last-child) {
    border-inline-end: ${({ theme }) => `1px solid ${theme.textColor}`};
  }

  thead .ant-table-cell::before {
    display: none;
  }

  thead .ant-table-cell:not(:last-child) {
    border-inline-end: ${({ theme }) => `2px solid ${theme.textColor}`};
  }

  thead .ant-table-cell {
    border-bottom: ${({ theme }) => `2px solid ${theme.textColor}`} !important;
  }

  height: 100%;

  .ant-tabs-nav::before {
    display: none;
  }

  .ant-tabs-tabpane,
  .ant-tabs-content {
    height: 100%;
  }

  .ant-tabs-nav-wrap {
    height: fit-content;
    @media (max-width: ${Breakpoints.DESKTOP}) {
      width: 0;
    }
  }

  .ant-tabs-tab {
    color: ${({ theme }) => theme.textColor} !important ;
    cursor: pointer;
    outline: none;
    font-size: 1.2rem;
    border-radius: 0;
    display: inline;
    margin: 0 !important;

    &:first-child {
      padding: 0 ${Spacing.s};
    }

    &:not(:first-child) {
      padding-inline-end: ${Spacing.s};
      padding-block: 0;
    }

    &:not(:nth-last-child(1)):not(:nth-last-child(2)) {
      border-inline-end: ${({ theme }) => `1px solid ${theme.textColor}`};
    }

    @media (min-width: ${Breakpoints.TABLET}) {
      font-size: 1.4rem;
    }
  }

  .ant-tabs-tab-btn {
    transition: all 0.2s !important;
    user-select: none;
    font-size: 1.25rem;
    line-height: normal !important;
  }

  .ant-tabs-tab-btn:active,
  .ant-tabs-tab-btn:focus:not(:focus-visible),
  .ant-tabs-tab:not(.ant-tabs-tab-active):hover {
    color: ${({ theme }) => theme.main} !important;
  }

  .ant-tabs-ink-bar {
    display: none !important;
  }

  .ant-tabs-nav .ant-tabs-nav-wrap::before {
    box-shadow: none !important;
  }

  .ant-tabs-nav-list {
    gap: ${Spacing.s};
    font-size: 1.5rem;
    background-color: ${({ theme }) => theme.light};
    width: fit-content;
    list-style-type: none;
    border-radius: ${Rounded.lg};
    overflow: hidden;
    padding: ${Spacing.s};
    margin-block: ${Spacing.m};
    overflow-x: auto;
  }

  .ant-tabs-nav-list::after,
  .ant-tabs-nav .ant-tabs-nav-wrap::after {
    display: none !important;
  }

  .ant-tabs-nav {
    border: 0 !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active {
    color: ${({ theme }) => theme.main} !important;
  }

  .ant-tabs-tab-active:hover,
  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${({ theme }) => theme.main} !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: inherit !important;
  }

  //this fixes flickering issue
  .ant-tabs-nav-operations {
    display: none !important;
  }

  @media (min-width: ${Breakpoints.TABLET}) {
    .ant-tabs-nav-list {
      overflow-x: hidden;
    }
  }
`;

interface AtiraTabsProps extends TabsProps {
  items: TabsProps['items'];
  className?: string;
  middleComponent?: React.ReactNode;
  sideComponent?: React.ReactNode;
}

export const AtiraTabs: React.FC<AtiraTabsProps> = ({
  className,
  items,
  sideComponent,
  middleComponent,
  ...props
}) => {
  return (
    <StyledTabs
      tabBarExtraContent={sideComponent}
      className={className}
      items={items}
      {...props}
      animated
    />
  );
};
