import { DefaultOptionType } from 'antd/es/select';
import { TFunction } from 'i18next';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraSelect } from '../../../components/AtiraSelect';
import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { EditAtiraLinkDto } from '../../../model/atira-link/dto/EditAtiraLinkDto';
import { AtiraLinkLang } from '../../../model/settings/types/AtiraLinkLang.enum';
import { atiraLinkSliceSelectors } from '../../../redux/nerve/atira-link.selector';
import { AtiraLinkActions } from '../../../redux/nerve/atira-link.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { userSliceSelectors } from '../../../redux/user/user.selector';
import { Rounded } from '../../../theme/Rounded';
import { Spacing } from '../../../theme/Spacing';
import { AtiraToast } from '../../../utils/AtiraToast';

const SectionTitle = styled(Text)`
  color: ${({ theme }) => theme.textColor};
  font-weight: bold;
  font-size: 1.3rem;
`;

const SectionWrapper = styled(Flex)`
  flex-direction: column;
  gap: ${Spacing.s};
  width: 100%;
`;

const Section = styled(Flex)`
  background-color: ${({ theme }) => theme.light};
  border-radius: ${Rounded.lg};
  padding: ${Spacing.m};
  flex-direction: column;
  gap: ${Spacing.s};
`;

const StyledButton = styled(Button)`
  height: 2.3rem;
  width: 10rem;
  margin-top: ${Spacing.l};
`;

const languageOptions = (t: TFunction) =>
  [
    { label: t('common.arabic'), value: AtiraLinkLang.AR },
    { label: t('common.english'), value: AtiraLinkLang.EN },
  ] as DefaultOptionType[];

export const SettingsLanguage: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const atiraLink = useAppSelector(atiraLinkSliceSelectors.selectUserAtiraLink);
  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;

  const { control, getValues, handleSubmit } = useForm<EditAtiraLinkDto>({
    defaultValues: {
      atiraLinkId: atiraLink?._id,
      userId: userId,
      settings: {
        lang: {
          current: atiraLink?.settings?.lang?.current || AtiraLinkLang.EN,
        },
      },
    },
  });

  const dispatch = useAppDispatch();

  const onUpdate = async () => {
    try {
      setLoading(true);

      const dto = getValues();

      if (dto.settings?.lang?.current !== atiraLink?.settings?.lang?.current) {
        await dispatch(AtiraLinkActions.editAtiraLink(dto)).unwrap();
        await dispatch(AtiraLinkActions.getUserAtiraLink({ userId })).unwrap();
      }

      AtiraToast.success(t('settings.language.update.success'));
    } catch (e) {
      console.log(e);
      AtiraToast.apiError(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex flexDirection="column" gap="m">
      <SectionWrapper>
        <SectionTitle>{t('common.language')}</SectionTitle>

        <Section>
          <Text color="subTextColor">{t('settings.language.description')}</Text>

          <Controller
            control={control}
            name="settings.lang.current"
            render={({ field: { value, onChange } }) => (
              <AtiraSelect
                value={value}
                onChange={onChange}
                options={languageOptions(t)}
                title={t('common.language')}
                containerStyle={{ width: '15rem' }}
              />
            )}
          />

          <StyledButton loading={loading} onClick={handleSubmit(onUpdate)}>
            {t('common.update')}
          </StyledButton>
        </Section>
      </SectionWrapper>
    </Flex>
  );
};
