import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '../../components/Button';
import { CopyButton } from '../../components/CopyButton';
import { Flex } from '../../components/Flex';
import { Input } from '../../components/Input';
import { Text } from '../../components/Text';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { shortenUrlActions } from '../../redux/url/url.slice';
import { userSliceSelectors } from '../../redux/user/user.selector';
import { Breakpoints } from '../../theme/Breakpoints';
import { Spacing } from '../../theme/Spacing';
import { AtiraToast } from '../../utils/AtiraToast';

const StyledButton = styled(Button)`
  font-size: 1rem;
  height: 4rem;
  width: 100%;

  @media (min-width: ${Breakpoints.TABLET}) {
    width: 8rem;
    font-size: 1.3rem;
  }
`;

const InputButtonWrapper = styled(Flex)`
  align-items: center;
  flex-wrap: wrap;
  gap: ${Spacing.l};
  max-width: 32rem;

  @media (min-width: ${Breakpoints.TABLET}) {
    flex-wrap: nowrap;
  }
`;

const ResultWrapper = styled(Flex)`
  max-width: 32rem;
  border: 2px solid ${({ theme }) => theme.textColor};
  gap: ${Spacing.l};
  padding: ${Spacing.m};
  flex-wrap: wrap;

  & > div:nth-child(2) {
    border-top: 2px solid ${({ theme }) => theme.textColor};
    padding-top: ${Spacing.s};
  }

  @media (min-width: ${Breakpoints.TABLET}) {
    flex-wrap: nowrap;

    & > div:nth-child(2) {
      border-top: 0;
      padding-top: 0;
    }

    & > div:nth-child(2) {
      border-inline-start: 2px solid ${({ theme }) => theme.textColor};
      padding-inline-start: ${Spacing.s};
    }
  }
`;

const ResultPart = styled(Flex)`
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: ${Spacing.m};

  @media (min-width: ${Breakpoints.TABLET}) {
    width: 50%;
    justify-content: flex-start;
  }
`;

export const URLShortner = () => {
  const { t } = useTranslation();

  const location = useLocation();
  const { nerveURL } = location.state || {};

  const [url, setUrl] = useState('');
  const [currentShortURL, setCurrentShortURL] = useState('');
  const [currentOriginalURL, setCurrentOriginalURL] = useState('');
  const [loading, setLoading] = useState(false);

  const loggedInUserId = useAppSelector(
    userSliceSelectors.selectLoggedInUserId,
  )!;

  const dispatch = useAppDispatch();
  const [isUrlValid, setIsUrlValid] = useState(true);

  const fetchShortURL = async () => {
    try {
      setLoading(true);

      if (!url) {
        setIsUrlValid(false);
        AtiraToast.error(t('shorten_url.create.error.enter_url'));
        return;
      }

      setIsUrlValid(true);

      const response = await dispatch(
        shortenUrlActions.getShortURL({
          userId: loggedInUserId!,
          originalURL: url,
        }),
      ).unwrap();

      setCurrentOriginalURL(response.url.originalURL);
      setCurrentShortURL(response.url.shortURL!);

      await dispatch(
        shortenUrlActions.getUserShortURLs({ userId: loggedInUserId }),
      ).unwrap();
      AtiraToast.success(t('shorten_url.create.success'));
      setCurrentShortURL(response.url.shortURL!);
      setUrl('');
    } catch (e: any) {
      console.log(e);
      AtiraToast.apiError(e);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (nerveURL) {
      setUrl(nerveURL);
    }
  }, [nerveURL]);
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      gap="xxl"
    >
      <Text align="center" fontSize="xl">
        {t('shorten_url.title')}
      </Text>
      <Text align="center" fontSize="m" color="subTextColor">
        {t('shorten_url.description')}
      </Text>

      <InputButtonWrapper>
        <Input
          value={url}
          placeholder={t('shorten_url.input_placeholder')}
          height="4rem"
          border={isUrlValid ? '2px solid black' : '2px solid red'}
          padding=" 1rem"
          style={{ fontSize: '1.2rem' }}
          onChange={(e) => {
            setUrl(e.currentTarget.value);
            setIsUrlValid(true);
          }}
        />
        <StyledButton onClick={fetchShortURL} loading={loading}>
          {t('shorten_url.button_text')}
        </StyledButton>
      </InputButtonWrapper>

      {currentShortURL ? (
        <ResultWrapper>
          <ResultPart>
            <Text fontSize="l">{t('shorten_url.original_url')}</Text>
            <Text wordBreak="break-all">{currentOriginalURL}</Text>
          </ResultPart>

          <ResultPart>
            <Text fontSize="l">{t('shorten_url.response.new_url')}</Text>

            <Flex
              width={'100%'}
              gap="m"
              flexWrap="wrap"
              justifyContent="center"
            >
              <Text wordBreak="break-all">{currentShortURL}</Text>
              <CopyButton copy={currentShortURL} />
            </Flex>
          </ResultPart>
        </ResultWrapper>
      ) : null}
    </Flex>
  );
};
