import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { AtiraLinkReport } from '../../../model/atira-link/AtiraLinkReport';
import { Spacing } from '../../../theme/Spacing';

const Container = styled(Flex)`
  flex-direction: column;
  gap: ${Spacing.s};
  padding: 0 ${Spacing.s};
  width: 100%;
`;

const BoldSpan = styled.span`
  font-weight: bold;
`;

type AdminReportProps = {
  report: AtiraLinkReport;
};

export const AdminReport: React.FC<AdminReportProps> = ({ report }) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'admin.reports.report',
  });

  return (
    <Container>
      <Text>
        <BoldSpan>{t('name')}</BoldSpan>
        {report.linkName}
      </Text>

      <Text>
        <BoldSpan>{t('reason')}</BoldSpan>
        {t(`reason.${report.reportReason.toLowerCase().replaceAll(' ', '_')}`)}
      </Text>

      {report.reportDescription ? (
        <Text>
          <BoldSpan>{t('description')}</BoldSpan>
          {report.reportDescription}
        </Text>
      ) : null}
    </Container>
  );
};
