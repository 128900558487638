import { faEllipsis } from '@fortawesome/free-solid-svg-icons/faEllipsis';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faTableList } from '@fortawesome/free-solid-svg-icons/faTableList';
import { Dropdown, MenuProps, TableProps } from 'antd';
import { TFunction } from 'i18next';
import styled from 'styled-components';

import { AtiraIcon } from '../../../components/AtiraIcon';
import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { AtiraLink } from '../../../model/atira-link/AtiraLink';
import { AtiraLinkReport } from '../../../model/atira-link/AtiraLinkReport';
import { Spacing } from '../../../theme/Spacing';

const ActionWrapper = styled(Flex)`
  align-items: center;
  justify-content: flex-start;
  gap: ${Spacing.s};
  color: ${({ theme }) => theme.main};
`;

const MenuButton = styled(Button)`
  margin: 0;
  background-color: ${({ theme }) => theme.transparent};
  color: ${({ theme }) => theme.main};
`;

type FieldFunctions = {
  t: TFunction;
  readReports: ATVoidFunction<AtiraLink>;
};

const getFieldWidth = (field: keyof AtiraLink | 'actions') => {
  switch (field) {
    case 'actions':
      return '5%';
    case 'linkName':
      return 100;
    case 'reports':
      return 50;

    default:
      return 100;
  }
};

const getFieldInfo = (
  column: keyof AtiraLink | 'actions',
  functions: FieldFunctions,
) => {
  const { t } = functions;

  switch (column) {
    case 'actions':
      return {
        title: t('common.actions'),
        key: 'table-actions',
        data: column,
        render: (link: AtiraLink) => {
          const items: MenuProps['items'] = [
            {
              key: 'read-reports',
              label: (
                <ActionWrapper>
                  <AtiraIcon icon={faTableList} color="main" />
                  {t('common.reports')}
                </ActionWrapper>
              ),
              onClick: () => functions.readReports(link),
            },
            {
              key: 'visit-link',
              label: (
                <ActionWrapper>
                  <AtiraIcon icon={faEye} color="main" />
                  {t('common.visit')}
                </ActionWrapper>
              ),
              onClick: () =>
                window.open(`https://atrl.ink/${link.linkName}`, '_blank'),
            },
          ];

          return (
            <Dropdown menu={{ items }} trigger={['click']}>
              <MenuButton icon={faEllipsis} />
            </Dropdown>
          );
        },
        fixed: 'left',
        width: getFieldWidth(column),
      };

    case 'linkName':
      return {
        title: t('admin.reports.report.name').replace(':', ''),
        key: 'table-name',
        data: column,
        render: (link: AtiraLinkReport) => {
          return link.linkName ? <Text>{link.linkName}</Text> : '';
        },
        width: getFieldWidth(column),
      };

    case 'reports':
      return {
        title: t('common.reports'),
        key: 'table-reports',
        data: column,
        render: (link: AtiraLink) => {
          return <Text>{String(link.reports?.length || 0)}</Text>;
        },
        width: getFieldWidth(column),
      };
  }
};

export function createAdminReportsTableColumns(functions: FieldFunctions) {
  const tableColumnsKeys: (keyof AtiraLink | 'actions')[] = [
    'actions',
    'linkName',
    'reports',
  ];

  return tableColumnsKeys.map((c) =>
    getFieldInfo(c, functions),
  ) as TableProps<AtiraLink>['columns'];
}
