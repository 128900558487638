import { faArrowUpFromBracket } from '@fortawesome/free-solid-svg-icons/faArrowUpFromBracket';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons/faArrowUpRightFromSquare';
import { faCircleUser } from '@fortawesome/free-solid-svg-icons/faCircleUser';
import { faGear } from '@fortawesome/free-solid-svg-icons/faGear';
import { faLink } from '@fortawesome/free-solid-svg-icons/faLink';
import { faMoon } from '@fortawesome/free-solid-svg-icons/faMoon';
import { faQrcode } from '@fortawesome/free-solid-svg-icons/faQrcode';
import { faSun } from '@fortawesome/free-solid-svg-icons/faSun';
import { faTableColumns } from '@fortawesome/free-solid-svg-icons/faTableColumns';
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons/faUpRightFromSquare';
import { faUsersGear } from '@fortawesome/free-solid-svg-icons/faUsersGear';
import { Menu, MenuProps, Tooltip } from 'antd';
import { MenuItemType } from 'antd/es/menu/interface';
import { TFunction } from 'i18next';
import { Fragment, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavigateFunction, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';

import { Themes, useTheme } from '../ThemeContext';
import { Images } from '../assets';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { userSliceSelectors } from '../redux/user/user.selector';
import { userActions } from '../redux/user/user.slice';
import { ShareDrawer } from '../routes/shared/components/ShareDrawer';
import { SideBarRoutes } from '../routes/types/SideBarRoutes.enum';
import { Breakpoints } from '../theme/Breakpoints';
import { Rounded } from '../theme/Rounded';
import { Spacing } from '../theme/Spacing';
import { truncateString } from '../utils/String';
import { AtiraDropdown } from './AtiraDropdown';
import { AtiraIcon } from './AtiraIcon';
import { Button } from './Button';
import { CopyButton } from './CopyButton';
import { Flex } from './Flex';
import { AtiraImage } from './Image';
import { LanguageSwitcher } from './LanguageSwitcher';
import { TextWithTruncated } from './TextWithTruncated';

const UserProfileWrapper = styled(Flex)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${Rounded.circle};
  overflow: hidden;
  cursor: pointer;
  width: 85%;
  aspect-ratio: 1 / 1;
  margin: auto;
`;

const SideMenuThemedStyle = createGlobalStyle<{ collapsed: boolean }>`
  .ant-menu {
    background-color: ${({ theme }) => theme.main};
  }

  .ant-menu-title-content {
    display: flex;
  }

  .ant-menu-item:first-child {
    margin-bottom: ${Spacing.l};
    padding: ${Spacing.m} !important;
    overflow: visible;
  }

  .ant-menu-item  {
    padding: 0 !important;
    display: flex !important;
    align-items: center;
    gap: ${Spacing.m};
    margin: 0px;
    font-size: 1.6rem;
    height: 3.3rem;
    width: 100%;
    justify-content: center;
    padding-inline-start: ${({ collapsed }) => (collapsed ? '0' : Spacing.m)} !important;
  }

  .ant-menu-item div {
    color: ${({ theme }) => theme.lightTextColor};
  }

  .ant-menu-item span {
    color: ${({ theme }) => theme.sub} !important;
  }

  .ant-menu-title-content path {
    fill: ${({ theme }) => theme.lightTextColor};
  }

  .ant-menu-item-selected path {
    fill: ${({ theme }) => theme.main};
  }

  .ant-menu-item-selected div {
    color: ${({ theme }) => theme.main};
  }

  .toggle-theme:active, .translate:active, .user-profile:active {
    background-color: ${({ theme }) => theme.transparent} !important;
  }

  @media (max-width:${Breakpoints.DESKTOP}) {
    .ant-menu {
      display: flex;
      flex-direction: column;
    }
  }
`;

const StyledMenu = styled(Menu)`
  position: relative;
  padding: 0;
  border-inline-end: 0 !important;

  @media (max-width: ${Breakpoints.DESKTOP}) {
    .ant-menu {
      height: 100%;
    }
    .ant-menu-item {
      padding: 0;
      display: flex;
    }

    .ant-menu-item.user-profile {
      overflow: visible;
    }

    .ant-menu-item:first-child {
      padding: 0 !important;
    }
  }
`;

const LangSwitcherWrapper = styled(Flex)`
  button {
    flex-direction: column;
    gap: 0.2rem;
  }
`;

const ShareButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: ${({ theme }) => theme.textColor};
  background-color: ${({ theme }) => theme.transparent};
  gap: ${Spacing.m};
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  display: flex;
  gap: ${Spacing.m};
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.black};
  font-size: 1rem;
`;

const MenuItemWrapper = styled(Flex)`
  align-items: center;
  gap: ${Spacing.s};
  color: inherit;
`;

const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.transparent};
  margin: 0;
  padding: 0;
`;

const StyledCopyButton = styled(CopyButton)`
  padding: 0;
`;

const SettingsLink = styled(Link)`
  text-decoration: none;
  display: flex;
`;

const CollapsedLogoLink = styled(Link)`
  text-decoration: none;
  width: 75%;
  margin: auto;
`;

export const SideMenu: React.FC<{ collapsed: boolean }> = ({ collapsed }) => {
  const [shareDrawerVisible, setShareDrawerVisible] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { width } = useWindowDimensions();
  const { theme, toggleTheme, currentTheme } = useTheme();

  const dispatch = useAppDispatch();

  const user = useAppSelector(userSliceSelectors.selectLoggedInUser);

  const onLogout = () => {
    dispatch(userActions.logout());
    navigate('/login', { replace: true });
  };

  const getMenuItems = (config: {
    t: TFunction;
    navigate: NavigateFunction;
    collapsed: boolean;
  }): Required<MenuProps>['items'] => {
    const { t, navigate, collapsed } = config;
    const userItems: MenuProps['items'] = [
      {
        key: 'g1',
        type: 'group',
        children: [
          {
            key: '1',
            title: '',
            label: user?.email ? (
              <Flex alignItems="center" justifyContent="center" gap={'s'}>
                <StyledCopyButton copy={user.email} />

                <TextWithTruncated length={20} textProps={{ direction: 'ltr' }}>
                  {user.email}
                </TextWithTruncated>
              </Flex>
            ) : (
              t('common.user_email')
            ),
          },
          {
            key: '2',
            title: '',
            label: (
              <StyledLink to={'/admin'}>
                {t('admin.header.title')}

                <AtiraIcon icon={faUsersGear} />
              </StyledLink>
            ),
          },
          {
            key: '3',
            title: '',
            label: (
              <ShareButton onClick={() => setShareDrawerVisible(true)}>
                {t('common.share')}

                <AtiraIcon icon={faArrowUpFromBracket} color="textColor" />
              </ShareButton>
            ),
          },
        ],
      },
      {
        key: '4',
        title: '',
        label: <hr />,
      },
      {
        key: '5',
        label: (
          <Button
            onClick={onLogout}
            fontSize="1rem"
            backgroundColor="transparent"
            color="textColor"
            border={`0.1rem solid ${theme.main}`}
            textAlign="center"
            width="100%"
          >
            {t('header.logout')}
          </Button>
        ),
        title: '',
        className: 'logout-button',
      },
    ];

    const items: MenuItemType[] = [
      {
        key: 'atira-link',
        label: !collapsed ? (
          <Flex>
            <Link to={'/dashboard'}>
              <AtiraImage src={Images.AtiraLinkSubLogo} />
            </Link>
          </Flex>
        ) : (
          <CollapsedLogoLink to={'/dashboard'}>
            <AtiraImage src={Images.AtiraLinkSubIcon} />
          </CollapsedLogoLink>
        ),
        title: '',
      },
      {
        key: SideBarRoutes.DASHBOARD,
        label: !collapsed ? (
          <MenuItemWrapper>
            <Flex width={'25%'}>
              <AtiraIcon icon={faTableColumns} />
            </Flex>
            {t('side_menu.dashboard')}
          </MenuItemWrapper>
        ) : (
          <AtiraIcon icon={faTableColumns} />
        ),
        onClick: () => navigate(SideBarRoutes.DASHBOARD),
        title: collapsed && width > 992 ? t('side_menu.dashboard') : '',
      },
      {
        key: SideBarRoutes.MY_LINK,
        label: !collapsed ? (
          <MenuItemWrapper>
            <Flex width={'25%'}>
              <AtiraIcon icon={faArrowUpRightFromSquare} />
            </Flex>
            {t('side_menu.my_link')}
          </MenuItemWrapper>
        ) : (
          <AtiraIcon icon={faUpRightFromSquare} />
        ),
        onClick: () => navigate(SideBarRoutes.MY_LINK),
        title: collapsed && width > 992 ? t('side_menu.my_link') : '',
      },
      {
        key: SideBarRoutes.MY_URLS,
        label: !collapsed ? (
          <MenuItemWrapper>
            <Flex width={'25%'}>
              <AtiraIcon icon={faLink} />
            </Flex>
            {t('side_menu.my_urls')}
          </MenuItemWrapper>
        ) : (
          <AtiraIcon icon={faLink} />
        ),
        onClick: () => navigate(SideBarRoutes.MY_URLS),
        title: collapsed && width > 992 ? t('side_menu.my_urls') : '',
      },
      {
        key: SideBarRoutes.MY_QRS,
        label: !collapsed ? (
          <MenuItemWrapper>
            <Flex width={'25%'}>
              <AtiraIcon icon={faQrcode} />
            </Flex>
            {t('side_menu.my_qrs')}
          </MenuItemWrapper>
        ) : (
          <AtiraIcon icon={faQrcode} />
        ),
        onClick: () => navigate(SideBarRoutes.MY_QRS),
        title: collapsed && width > 992 ? t('side_menu.my_qrs') : '',
      },
    ];

    if (width <= Number(Breakpoints.DESKTOP.replace('px', ''))) {
      items.push(
        {
          title: '',
          key: 'profile',
          label: (
            <AtiraDropdown
              overlayStyle={{ width: '15rem', borderRadius: '0.5rem' }}
              items={userItems}
              placement="topRight"
              arrow={{ pointAtCenter: true }}
              trigger={['click']}
            >
              <UserProfileWrapper>
                {user?.picture ? (
                  <AtiraImage src={user.picture} />
                ) : (
                  <AtiraIcon icon={faCircleUser} size="2x" color="sub" />
                )}
              </UserProfileWrapper>
            </AtiraDropdown>
          ),
          className: 'user-profile',
          style: { marginTop: 'auto' },
        },
        {
          key: 'translate',
          title: '',
          label: (
            <LangSwitcherWrapper>
              <LanguageSwitcher
                popupPlacement="topRight"
                color="lightTextColor"
              />
            </LangSwitcherWrapper>
          ),
          style: { height: 'auto', marginTop: Spacing.m },
          className: 'translate',
        },
        {
          key: 'toggle-theme',
          title: '',
          label: (
            <StyledButton
              icon={currentTheme === Themes.LIGHT ? faMoon : faSun}
              onClick={toggleTheme}
              color="textColor"
              iconWidth="2x"
            />
          ),
          className: 'toggle-theme',
        },
        {
          key: 'settings',
          title: '',
          label: (
            <SettingsLink to={'/settings'}>
              <AtiraIcon icon={faGear} />
            </SettingsLink>
          ),
        },
      );
    }

    return items;
  };

  const getSelectedKey = useMemo(() => {
    switch (true) {
      case pathname.startsWith(SideBarRoutes.MY_LINK):
        return SideBarRoutes.MY_LINK;
      default:
        return pathname;
    }
  }, [pathname]);

  return (
    <Fragment>
      <StyledMenu
        mode="inline"
        theme="dark"
        items={getMenuItems({ t, navigate, collapsed })}
        selectedKeys={[getSelectedKey]}
      />

      <ShareDrawer
        open={shareDrawerVisible}
        onClose={() => setShareDrawerVisible(false)}
      />
      <SideMenuThemedStyle theme={theme} collapsed={collapsed} />
    </Fragment>
  );
};
