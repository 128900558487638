import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Images } from '../../../assets';
import { Blog } from '../../../components/Blog';
import { Flex } from '../../../components/Flex';
import { AtiraImage } from '../../../components/Image';
import { blogsSliceSelectors } from '../../../redux/blogs/blogs.selector';
import { useAppSelector } from '../../../redux/store';
import { Rounded } from '../../../theme/Rounded';
import { Spacing } from '../../../theme/Spacing';

const MoreArticlesLink = styled.a`
  background-color: ${({ theme }) => theme.main};
  color: ${({ theme }) => theme.light};
  text-decoration: none;
  display: block;
  padding: ${Spacing.s};
  font-size: 1.2rem;
  width: 100%;
  text-align: center;
  border-radius: ${Rounded.md};
  transition: all 0.3s;
  margin-bottom: ${Spacing.l};

  &:hover {
    background-color: ${({ theme }) => theme.lightMain};
    color: ${({ theme }) => theme.light};
  }
`;

const Container = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${Spacing.m};
  padding-bottom: ${Spacing.m};
`;

export const NotificationsBlogTab: React.FC = () => {
  const { t } = useTranslation();

  const blogs = useAppSelector(blogsSliceSelectors.selectBlogs);

  return (
    <Container>
      {blogs.map((p) => (
        <Flex key={p.id}>
          <Blog post={p} />
        </Flex>
      ))}

      <MoreArticlesLink href="https://www.atiragrup.com" target="_blank">
        {t('header.bell.drawer.articles.more')}
      </MoreArticlesLink>

      <AtiraImage src={Images.AtiraLinkMainLogo} width="70%" />
    </Container>
  );
};
