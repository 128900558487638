import { CollapseProps } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AtiraCollapse } from '../../../components/AtiraCollapse';
import { Drawer } from '../../../components/Drawer';
import { Flex } from '../../../components/Flex';
import { AtiraLink } from '../../../model/atira-link/AtiraLink';
import { AdminReport } from './AdminReport';

type AdminReportsReadDrawerProps = {
  reportedLink: AtiraLink | null;
  open: boolean;
  onClose: VoidFunction;
};

export const AdminReportsReadDrawer: React.FC<AdminReportsReadDrawerProps> = ({
  reportedLink,
  open = false,
  onClose,
}) => {
  const { t } = useTranslation();

  const items: CollapseProps['items'] = useMemo(() => {
    const reports = reportedLink?.reports?.map((report, index) => ({
      key: `${report.createdAt}-${index}`,
      label: t(
        `admin.reports.report.reason.${report.reportReason.toLowerCase().replaceAll(' ', '_')}`,
      ),
      children: <AdminReport report={report} />,
    }));

    return reports;
  }, [reportedLink?.reports]);

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t('admin.reports.drawer.title', {
        name: reportedLink?.linkName,
      })}
    >
      <Flex width={'100%'}>
        {items?.length ? <AtiraCollapse items={items} /> : null}
      </Flex>
    </Drawer>
  );
};
