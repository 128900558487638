import { createSlice } from '@reduxjs/toolkit';

import { AtiraLinkAxios } from '../../axios/atira-link/atira-link.axios';
import { AtiraLink } from '../../model/atira-link/AtiraLink';
import { AtiraLinkStatsResponseDto } from '../../model/atira-link/dto/AtiraLinkStatsResponseDto';
import { CreateAtiraLinkDto } from '../../model/atira-link/dto/CreateAtiraLinkDto';
import { EditAtiraLinkDto } from '../../model/atira-link/dto/EditAtiraLinkDto';
import { GetUserAtiraLinkResponseDto } from '../../model/atira-link/dto/GetUserAtiraLinksResponseDto';
import { AtiraLinkSettings } from '../../model/settings/types/AtiraLinkSettings';
import { GenericWithUserId } from '../../model/shared/GenericWithUserId';
import { AtiraThunk } from '../AtiraThunk';
import { userActions } from '../user/user.slice';
import { DeleteAtiraLinkDto } from './dto/DeleteAtiraLinkDto';
import { GenericDisableEnableAtiraLinkDto } from './dto/GenericDisableEnableAtiraLinkDto';

interface AtiraLinkReducer {
  atiraLink?: AtiraLink;
  atiraLinkLoading?: boolean;
  atiraLinkStats?: AtiraLinkStatsResponseDto;
  settings?: AtiraLinkSettings;
}

const initialState = Object.freeze<AtiraLinkReducer>({
  atiraLink: undefined,
  atiraLinkLoading: false,
  atiraLinkStats: undefined,
  settings: undefined,
});

const createAtiraLink = AtiraThunk<void, CreateAtiraLinkDto>(
  `/atira-link/create`,
  (dto) => AtiraLinkAxios.createAtiraLink(dto),
);

const editAtiraLink = AtiraThunk<void, EditAtiraLinkDto>(
  `/atira-link/edit`,
  (dto) => AtiraLinkAxios.editAtiraLinkDto(dto),
);

const getUserAtiraLink = AtiraThunk<
  GetUserAtiraLinkResponseDto,
  GenericWithUserId
>(`/atira-link/get`, (dto) => AtiraLinkAxios.getUserAtiraLink(dto));

const deleteUserAtiraLink = AtiraThunk<void, DeleteAtiraLinkDto>(
  `/atira-link/delete`,
  (dto) => AtiraLinkAxios.deleteAtiraLink(dto),
);

const disableUserAtiraLink = AtiraThunk<void, GenericDisableEnableAtiraLinkDto>(
  `/atira-link/disable`,
  (dto) => AtiraLinkAxios.disableAtiraLink(dto),
);

const enableUserAtiraLink = AtiraThunk<void, GenericDisableEnableAtiraLinkDto>(
  `/atira-link/enable`,
  (dto) => AtiraLinkAxios.enableAtiraLink(dto),
);

const AtiraLinkSlice = createSlice({
  name: 'atiraLinkSlice',
  initialState,
  reducers: {},
  extraReducers: ({ addCase }) => {
    addCase(getUserAtiraLink.pending, (state) => {
      state.atiraLinkLoading = true;
    });
    addCase(getUserAtiraLink.fulfilled, (state, action) => {
      state.atiraLink = action.payload.link;
      state.atiraLinkStats = action.payload.stats;
      state.atiraLinkLoading = false;
    });
    addCase(getUserAtiraLink.rejected, (state) => {
      state.atiraLinkLoading = false;
    });

    //@ts-ignore
    addCase(userActions.logout.fulfilled, (state: AtiraLinkReducer) => {
      return (state = initialState);
    });
  },
});

export const AtiraLinkActions = {
  createAtiraLink,
  editAtiraLink,
  getUserAtiraLink,
  deleteUserAtiraLink,
  disableUserAtiraLink,
  enableUserAtiraLink,
};

export default AtiraLinkSlice.reducer;
