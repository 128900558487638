import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { Tooltip } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraIcon } from '../../../components/AtiraIcon';
import { Button } from '../../../components/Button';
import { DeleteButton } from '../../../components/DeleteButton';
import { Flex } from '../../../components/Flex';
import { AtiraImage } from '../../../components/Image';
import { Text } from '../../../components/Text';
import { TextWithTruncated } from '../../../components/TextWithTruncated';
import { WarningModal } from '../../../components/WarningModal';
import { PageMeta } from '../../../model/meta/PageMeta';
import { AtiraQR } from '../../../model/qr/AtiraQR';
import { QRActions } from '../../../redux/QR/qr.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { userSliceSelectors } from '../../../redux/user/user.selector';
import { Rounded } from '../../../theme/Rounded';
import { Shadow } from '../../../theme/Shadow';
import { Spacing } from '../../../theme/Spacing';
import { AtiraToast } from '../../../utils/AtiraToast';
import { QRUtils } from '../QRUtils';
import { QRDownloadModal } from './QRDownloadModal';

const StyledText = styled(Text)`
  width: 10rem;
  white-space: wrap;
  word-break: break-all;
  display: flex;
  justify-content: center;
`;

const FlexWrap = styled(Flex)`
  gap: ${Spacing.s};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 20rem;
  background-color: ${({ theme }) => theme.light};
  padding: ${Spacing.s};
  border-radius: ${Rounded.lg};
  box-shadow: ${Shadow.MD};
`;

interface QRCodeProps {
  qr: AtiraQR;
  showDeleteButton?: boolean;
}

export const QRCode: React.FC<QRCodeProps> = ({
  qr,
  showDeleteButton = true,
}) => {
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileNameModalVisible, setFileNameModalVisible] = useState(false);

  const { t } = useTranslation();

  const loggedInUserId = useAppSelector(
    userSliceSelectors.selectLoggedInUserId,
  )!;

  const dispatch = useAppDispatch();

  const {
    fields: { data, size },
  } = qr;

  const imgUrl = QRUtils.generateQRImgURL(qr);

  const deleteQRWarning = () => {
    setIsWarningModalOpen(true);
  };

  const deleteQR = async () => {
    try {
      setLoading(true);

      await dispatch(
        QRActions.deleteQR({
          qrId: qr._id,
          userId: loggedInUserId,
        }),
      ).unwrap();

      await dispatch(
        QRActions.getUserQRs({
          userId: loggedInUserId,
          meta: PageMeta.create(),
        }),
      ).unwrap();

      AtiraToast.success(t('qr.delete.success'));
    } catch (e: any) {
      AtiraToast.apiError(e);
    } finally {
      setLoading(false);
      setIsWarningModalOpen(false);
    }
  };
  return (
    <FlexWrap>
      <AtiraImage style={{ height: 'auto', maxWidth: '10rem' }} src={imgUrl} />

      <StyledText align="end" fontSize="l" cutText>
        <TextWithTruncated>{data}</TextWithTruncated>
      </StyledText>

      <Text align="center" color="lightTextColor">
        {size}
      </Text>

      <Flex>
        {showDeleteButton ? <DeleteButton onDelete={deleteQRWarning} /> : null}
        <Tooltip title={t('common.download')}>
          <Button
            onClick={() => setFileNameModalVisible(true)}
            backgroundColor="transparent"
          >
            <AtiraIcon color="textColor" size="2x" icon={faDownload} />
          </Button>
        </Tooltip>
      </Flex>

      <WarningModal
        open={isWarningModalOpen}
        onClose={() => setIsWarningModalOpen(false)}
        title={t('qr.delete.modal.title')}
        description={t('qr.delete.modal.description')}
        onConfirm={deleteQR}
        loading={loading}
      />

      <QRDownloadModal
        isOpen={fileNameModalVisible}
        url={imgUrl}
        onClose={() => setFileNameModalVisible(false)}
      />
    </FlexWrap>
  );
};
