import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { t } from 'i18next';
import { debounce } from 'lodash';
import React, { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraSelect } from '../../components/AtiraSelect';
import { Button } from '../../components/Button';
import { Flex } from '../../components/Flex';
import { AtiraImage } from '../../components/Image';
import { Input } from '../../components/Input';
import { Text } from '../../components/Text';
import { PageMeta } from '../../model/meta/PageMeta';
import { DataType } from '../../model/qr/Types/DataType.enum';
import { QRFormat } from '../../model/qr/Types/QRFormat.enum';
import { CreateQRDto } from '../../model/qr/dto/CreateQRDto';
import { QRActions } from '../../redux/QR/qr.slice';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { userSliceSelectors } from '../../redux/user/user.selector';
import { Breakpoints } from '../../theme/Breakpoints';
import { Spacing } from '../../theme/Spacing';
import { AtiraToast } from '../../utils/AtiraToast';
import { QRUtils } from './QRUtils';
import { QRDownloadModal } from './components/QRDownloadModal';

const StyledInput = styled(Input)`
  border: 1px solid ${({ theme }) => theme.black};
  padding: ${Spacing.s} ${Spacing.s};
  background-color: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.black};
  font-size: 1rem;

  &::placeholder {
    color: ${({ theme }) => theme.black};
  }
`;

const CustomizationInput = styled(Input)`
  border: 1px solid ${({ theme }) => theme.black};
  height: 2.5rem;
  cursor: pointer;
  background-color: ${({ theme }) => theme.white};
  padding: ${Spacing.s};
  color: ${({ theme }) => theme.black};
  font-size: 1rem;
`;

const CreateButton = styled(Button)`
  width: 8rem;
  height: 2.5rem;
  font-size: 1.2rem;
`;

const InputsWrapper = styled(Flex)`
  flex-direction: column;
  gap: ${Spacing.m};
  width: 100%;
`;

const Container = styled(Flex)`
  gap: ${Spacing.m};
  flex-wrap: wrap;
  align-items: center;

  @media (min-width: ${Breakpoints.TABLET}) {
    flex-wrap: nowrap;
  }
`;

const Wrapper = styled(Flex)`
  margin-top: ${Spacing.l};
  flex-direction: column;
  width: 100%;
  gap: ${Spacing.m};

  @media (min-width: ${Breakpoints.TABLET}) {
    width: 70%;
  }
`;

const ResponseWrapper = styled(Flex)`
  flex-direction: column;
  align-items: center;
  gap: ${Spacing.s};
  padding: ${Spacing.m};
  max-width: 100%;

  @media (min-width: ${Breakpoints.TABLET}) {
    width: 30%;
  }
`;

const dataTypesOptions = [
  { value: DataType.URL, label: t('common.url') },
  { value: DataType.EMAIL, label: t('common.email') },
  { value: DataType.PHONE, label: t('common.phone') },
  { value: DataType.SMS, label: t('common.sms') },
  { value: DataType.TEXT, label: t('common.text') },
];

const formatOptions = [
  { value: QRFormat.PNG, label: 'PNG' },
  { value: QRFormat.SVG, label: 'SVG' },
];

export const QRGenerator: React.FC = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [previewURL, setPreviewURL] = useState('');
  const [qrDownloadModalVisible, setQrDownloadModalVisible] = useState(false);

  const { control, getValues, handleSubmit, reset, watch } =
    useForm<CreateQRDto>({
      defaultValues: {
        color: '#000000',
        bgcolor: '#ffffff',
        format: QRFormat.PNG,
        dataType: DataType.URL,
        size: 250,
        data: '',
      },
    });

  const selectedDataType = watch('dataType', DataType.URL);
  const selectedFormat = watch('format', QRFormat.PNG);
  const data = watch('data', '');

  const dispatch = useAppDispatch();

  const loggedInUserId = useAppSelector(
    userSliceSelectors.selectLoggedInUserId,
  )!;

  const dto = getValues();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const generateQRPreview = useCallback(
    debounce(() => {
      const { size, bgcolor, color, data, format, dataType } = getValues();

      const prefixedData = encodeURIComponent(
        QRUtils.transformDataBasedOnType(data, dataType),
      );

      if (!data) {
        return;
      }

      const imgUrl = `https://api.qrserver.com/v1/create-qr-code/?size=${size}&data=${prefixedData}&bgcolor=${encodeURIComponent(bgcolor.substring(1))}&color=${color.substring(1)}&format=${format}`;
      setPreviewURL(imgUrl);
    }, 1000),
    [getValues],
  );

  const createQR = async () => {
    try {
      setLoading(true);

      const payload = {
        ...getValues(),
        userId: loggedInUserId,
        data: QRUtils.transformDataBasedOnType(dto.data, dto.dataType),
        size: Number(dto.size),
      };

      await dispatch(QRActions.createQR(payload)).unwrap();
      await dispatch(
        QRActions.getUserQRs({
          userId: loggedInUserId,
          meta: PageMeta.create(),
        }),
      ).unwrap();
      setPreviewURL('');

      reset({
        color: '#000000',
        bgcolor: '#ffffff',
        format: getValues('format'),
        dataType: DataType.URL,
        size: 250,
        data: '',
      });

      AtiraToast.success(t('qr.create.success'));
    } catch (error) {
      console.log(error);
      AtiraToast.apiError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Wrapper>
        <Text>{t('qr.create.paragraph')}</Text>

        <Flex gap="m">
          <Controller
            name="data"
            control={control}
            rules={{
              required: {
                value: true,
                message: t('common.this_field_required'),
              },
            }}
            render={({ field: { value, onChange } }) => (
              <StyledInput
                type="text"
                placeholder={t('qr.create.input.content.placeholder')}
                value={value}
                onChange={(e) => {
                  onChange(e);
                  generateQRPreview();
                }}
              />
            )}
          />

          <Controller
            name="dataType"
            control={control}
            render={({ field: { onChange } }) => (
              <AtiraSelect
                value={{
                  label:
                    t(`common.${selectedDataType}`) ||
                    t('common.dropdown.select'),
                  value: selectedDataType,
                }}
                options={dataTypesOptions}
                onChange={onChange}
                containerStyle={{ width: '8rem' }}
              />
            )}
          />
        </Flex>

        <Text color="subTextColor" fontSize="s">
          {t('qr.create.save_note')}
        </Text>

        <InputsWrapper>
          <Controller
            name="size"
            control={control}
            render={({ field: { value, onChange } }) => (
              <CustomizationInput
                value={value}
                onChange={(e) => {
                  onChange(e);
                  generateQRPreview();
                }}
                type="number"
                lang="en"
                title={t('qr.create.input.size.label')}
                style={{ cursor: 'auto' }}
              />
            )}
          />

          <Controller
            name="color"
            control={control}
            render={({ field: { value, onChange } }) => (
              <CustomizationInput
                type="color"
                id="color"
                padding="0"
                value={value}
                onChange={(e) => {
                  onChange(e);
                  generateQRPreview();
                }}
                title={t('qr.create.input.color.label')}
              />
            )}
          />

          <Controller
            name="bgcolor"
            control={control}
            render={({ field: { value, onChange } }) => (
              <CustomizationInput
                type="color"
                id="bgcolor"
                padding="0"
                value={value}
                onChange={(e) => {
                  onChange(e);
                  generateQRPreview();
                }}
                title={t('qr.create.input.background_color.label')}
              />
            )}
          />

          <Controller
            name="format"
            control={control}
            render={({ field: { onChange } }) => (
              <AtiraSelect
                value={{
                  label: selectedFormat || t('common.dropdown.select'),
                  value: selectedFormat,
                }}
                options={formatOptions}
                onChange={(value: any) => {
                  onChange(value);
                  generateQRPreview();
                }}
                containerStyle={{ width: '100%' }}
                title={t('qr.create.input.image_type.label')}
              />
            )}
          />
        </InputsWrapper>

        <CreateButton
          loading={loading}
          onClick={handleSubmit(createQR)}
          disabled={!data}
        >
          {t('common.save')}
        </CreateButton>
      </Wrapper>

      {previewURL ? (
        <ResponseWrapper>
          <AtiraImage
            src={previewURL}
            width={getValues('size').toString()}
            height={getValues('size').toString()}
            style={{ maxWidth: '8rem', maxHeight: '8rem' }}
          />

          <Text color="subTextColor" fontSize="m">
            {`${dto.size} x ${dto.size}`}
          </Text>

          <Button
            onClick={() => setQrDownloadModalVisible(true)}
            padding="0"
            margin="0"
            backgroundColor="transparent"
            hover
            hoverColor="subTextColor"
            color="textColor"
            icon={faDownload}
            iconWidth="2x"
          />

          <Text align="center" color="subTextColor" fontSize="s">
            {t('qr.create.size_note')}
          </Text>
        </ResponseWrapper>
      ) : null}

      <QRDownloadModal
        url={previewURL}
        isOpen={qrDownloadModalVisible}
        onClose={() => setQrDownloadModalVisible(false)}
      />
    </Container>
  );
};
